import common_en from './locales/en/common.json'
import common_sv from './locales/sv/common.json'
import password_en from './locales/en/password.json'
import password_sv from './locales/sv/password.json'
import login_en from './locales/en/login.json'
import login_sv from './locales/sv/login.json'
import dashboard_en from './locales/en/dashboard.json'
import dashboard_sv from './locales/sv/dashboard.json'
import creditUsage_en from './locales/en/creditUsage.json'
import creditUsage_sv from './locales/sv/creditUsage.json'
import activations_en from './locales/en/activations.json'
import activations_sv from './locales/sv/activations.json'
import deactivations_en from './locales/en/deactivations.json'
import deactivations_sv from './locales/sv/deactivations.json'
import employees_en from './locales/en/employees.json'
import employees_sv from './locales/sv/employees.json'
import deviations_en from './locales/en/deviations.json'
import deviations_sv from './locales/sv/deviations.json'
import paySchedules_en from './locales/en/paySchedules.json'
import paySchedules_sv from './locales/sv/paySchedules.json'
import shifts_en from './locales/en/shifts.json'
import shifts_sv from './locales/sv/shifts.json'
import payrolls_en from './locales/en/payrolls.json'
import payrolls_sv from './locales/sv/payrolls.json'
import integrationLog_en from './locales/en/integrationLog.json'
import integrationLog_sv from './locales/sv/integrationLog.json'
import portalUsers_en from './locales/en/portalUsers.json'
import portalUsers_sv from './locales/sv/portalUsers.json'
import companySettings_en from './locales/en/companySettings.json'
import companySettings_sv from './locales/sv/companySettings.json'
import bookkeepingSettings_en from './locales/en/bookkeepingSettings.json'
import bookkeepingSettings_sv from './locales/sv/bookkeepingSettings.json'
import files_en from './locales/en/files.json'
import files_sv from './locales/sv/files.json'
import integrations_en from './locales/en/integrations.json'
import integrations_sv from './locales/sv/integrations.json'
import payoutBasis_en from './locales/en/payoutBasis.json'
import payoutBasis_sv from './locales/sv/payoutBasis.json'
import paymentNotifications_en from './locales/en/paymentNotifications.json'
import paymentNotifications_sv from './locales/sv/paymentNotifications.json'
import agiAdjustment_en from './locales/en/agiAdjustment.json'
import agiAdjustment_sv from './locales/sv/agiAdjustment.json'
import foraAdjustment_en from './locales/en/foraAdjustment.json'
import foraAdjustment_sv from './locales/sv/foraAdjustment.json'
import payrollFileProcessor_en from './locales/en/payrollFileProcessor.json'
import payrollFileProcessor_sv from './locales/sv/payrollFileProcessor.json'
import employeeImport_en from './locales/en/employeeImport.json'
import employeeImport_sv from './locales/sv/employeeImport.json'
import employerInitiatedPayout_en from './locales/en/employerInitiatedPayout.json'
import employerInitiatedPayout_sv from './locales/sv/employerInitiatedPayout.json'
import portalRoles_en from './locales/en/portalRoles.json'
import portalRoles_sv from './locales/sv/portalRoles.json'

const translations = {
    en: {
        common: {...common_en},
        password: {...password_en},
        login: {...login_en},
        dashboard: {...dashboard_en},
        creditUsage: {...creditUsage_en},
        activations: {...activations_en},
        deactivations: {...deactivations_en},
        employees: {...employees_en},
        deviations: {...deviations_en},
        paySchedules: {...paySchedules_en},
        shifts: {...shifts_en},
        payrolls: {...payrolls_en},
        integrationLog: {...integrationLog_en},
        portalUsers: {...portalUsers_en},
        companySettings: {...companySettings_en},
        bookkeepingSettings: {...bookkeepingSettings_en},
        files: {...files_en},
        integrations: {...integrations_en},
        payoutBasis: {...payoutBasis_en},
        paymentNotifications: {...paymentNotifications_en},
        agiAdjustment: {...agiAdjustment_en},
        foraAdjustment: {...foraAdjustment_en},
        payrollFileProcessor: {...payrollFileProcessor_en},
        employeeImport: {...employeeImport_en},
        employerInitiatedPayout: {...employerInitiatedPayout_en},
        portalRoles: {...portalRoles_en}
    },
    sv: {
        common: {...common_sv},
        password: {...password_sv},
        login: {...login_sv},
        dashboard: {...dashboard_sv},
        creditUsage: {...creditUsage_sv},
        activations: {...activations_sv},
        deactivations: {...deactivations_sv},
        employees: {...employees_sv},
        deviations: {...deviations_sv},
        paySchedules: {...paySchedules_sv},
        shifts: {...shifts_sv},
        payrolls: {...payrolls_sv},
        integrationLog: {...integrationLog_sv},
        portalUsers: {...portalUsers_sv},
        companySettings: {...companySettings_sv},
        bookkeepingSettings: {...bookkeepingSettings_sv},
        files: {...files_sv},
        integrations: {...integrations_sv},
        payoutBasis: {...payoutBasis_sv},
        paymentNotifications: {...paymentNotifications_sv},
        agiAdjustment: {...agiAdjustment_sv},
        foraAdjustment: {...foraAdjustment_sv},
        payrollFileProcessor: {...payrollFileProcessor_sv},
        employeeImport: {...employeeImport_sv},
        employerInitiatedPayout: {...employerInitiatedPayout_sv},
        portalRoles: {...portalRoles_sv}
    }
}

export default translations
