import {faCheckCircle, faCircle, faEdit, faTrash, faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {createUseStyles} from 'react-jss'
import {Colors} from "../../constants/colors"
import {CustomerPortalUser, PortalRoleModel} from "../../redux/types"
import Card from '../Card'
import {useTranslation} from 'react-i18next'

interface Props {
  portalRole: PortalRoleModel
  employerPortalUsers: CustomerPortalUser[]
  onEditClick: (portalRole: PortalRoleModel) => void
  onDeleteClick: (portalRole: PortalRoleModel) => void
}

const PortalRoleCard = ({portalRole, employerPortalUsers, onEditClick, onDeleteClick}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'portalRoles']);

  const usersInRole = employerPortalUsers.filter(x => x.portalRoles.some(y => y.id == portalRole.id))

  const renderProperty = (propertyName: keyof PortalRoleModel) => {
    const enabled = !!portalRole[propertyName];
    const name = propertyName as string;

    return (
      <div className={styles.propertyContainer}>
        <FontAwesomeIcon
          icon={enabled ? faCheckCircle : faCircle}
          color={enabled ? Colors.euro_800 : Colors.gray3}
        />
        <span>{t(name, {ns: 'portalRoles'})}</span>
      </div>
    );
  };

  const renderUser = (user: CustomerPortalUser) => {
    return (
      <div key={user.portalUserId} className={styles.propertyContainer}>
        <FontAwesomeIcon
          icon={faUser}
          color={Colors.euro_800}
        />
        <span>{user.givenName} {user.familyName}</span>
        <span>{user.email}</span>
      </div>
    )
  }

  return (
    <Card className={styles.card}>
      <div className={styles.cardHeader}>
        <h3 style={{color: Colors.euro_800}}>{portalRole.name}</h3>
        <div className={styles.toolbar}>
          <FontAwesomeIcon
            className={styles.button}
            icon={faEdit}
            color={Colors.gray1}
            title={t('edit', {ns: 'common'})}
            onClick={() => onEditClick(portalRole)}
          />
          <FontAwesomeIcon
            className={styles.button}
            icon={faTrash}
            color={Colors.gray1}
            title={t('delete', {ns: 'common'})}
            onClick={() => onDeleteClick(portalRole)}
          />
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.subCard}>
          <h4>{t('policies', {ns: 'portalRoles'})}</h4>

          <div className={styles.row}>
            {renderProperty("allowManageUsers")}
            {renderProperty("allowManagePaySchedules")}
            {renderProperty("allowManagePayroll")}
            {renderProperty("allowManageEmployees")}
            {renderProperty("allowManageShifts")}
            {renderProperty("allowManagePayDeviations")}
            {renderProperty("allowManageEmployeeActivations")}
            {renderProperty("allowManageCompanySettings")}
            {renderProperty("allowReadCreditReport")}
            {renderProperty("allowManageBookkeepingSettings")}
            {renderProperty("allowManageFora")}
            {renderProperty("allowManageAgi")}
            {renderProperty("allowViewIntegrationLog")}
            {renderProperty("allowManageIntegrations")}
          </div>
        </div>

        <div className={styles.subCard}>
          <h4>{t('reportsAndNotifications', {ns: 'portalRoles'})}</h4>

          <div className={styles.row}>
            {renderProperty("receiveAgiAdjustmentReminder")}
            {renderProperty("receiveCreditReport")}
            {renderProperty("receiveActivationNotification")}
            {renderProperty("receivePayrollSystemImportFileNotification")}
            {renderProperty("receiveDeductionRepaymentNotification")}
            {renderProperty("receivePayrollFileReminder")}
            {renderProperty("receivePaydayGenerationNotification")}
            {renderProperty("receivePayrollFileValidationErrorNotification")}
          </div>
        </div>

        <div className={styles.subCard} style={{backgroundColor: Colors.grayBackground}}>
          <h4>{t('portalUsers', {ns: 'portalRoles'})}</h4>

          <div className={styles.row}>
            {usersInRole.map(x => renderUser(x))}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PortalRoleCard

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    gap: 10,
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    flex: 1,
    flexWrap: 'wrap'
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 4
  },
  subCard: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    borderRadius: 8,
    padding: 10,
    gap: 10
  },
  propertyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center'
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10
  },
  button: {
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro_600,
      cursor: 'pointer',
    }
  }
})
