import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomerPortalUser } from '../types'

interface PortalUserState {
    loading: boolean
    portalUsers: CustomerPortalUser[]
    pageIndex: number
    pageSize: number    
    error: Error | string | null
}

const initialState: PortalUserState = {
    loading: false,
    portalUsers: [],
    pageIndex: 1,
    pageSize: 15,
    error: null
}

export const portalUserSlice = createSlice({
    name: 'portalUser',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        portalUsersFetched: (state, action: PayloadAction<CustomerPortalUser[]>) => {
            state.loading = false
            state.portalUsers = action.payload
            state.pageIndex = 1
            state.error = null
        },
        portalUsersFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        portalUserCreated: (state, action: PayloadAction<CustomerPortalUser>) => {
            state.loading = false
            state.portalUsers = [action.payload, ...state.portalUsers]
            state.pageIndex = 1
            state.error = null
        },
        portalUserCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        portalUserUpdated: (state, action: PayloadAction<CustomerPortalUser>) => {
            const portalUsersClone = [...state.portalUsers]
            const indexToUpdate = portalUsersClone.findIndex(x => x.portalUserId === action.payload.portalUserId)
            portalUsersClone[indexToUpdate] = action.payload

            state.loading = false
            state.portalUsers = portalUsersClone
            state.error = null
        },
        portalUserUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        portalUserDeleted: (state, action: PayloadAction<CustomerPortalUser>) => {
            state.loading = false
            state.portalUsers = state.portalUsers.filter(x => x.portalUserId !== action.payload.portalUserId)
            state.error = null
        },
        portalUserDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
    }
})

export const { 
    loading, 
    portalUsersFetched, 
    portalUsersFetchFailure, 
    portalUserCreated, 
    portalUserCreateFailure, 
    portalUserUpdated, 
    portalUserUpdateFailure, 
    portalUserDeleted, 
    portalUserDeleteFailure, 
    pageIndexChanged 
} = portalUserSlice.actions

export default portalUserSlice.reducer