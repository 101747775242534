import useEmployeeActions from "./useEmployeeActions"
import useEmployeeActivationActions from "./useEmployeeActivationActions"
import useEmployeeDeactivationActions from "./useEmployeeDeactivationActions"
import useFileActions from "./useFileActions"
import usePayrollActions from "./usePayrollActions"
import {userHasAuthorization} from "../util/role";
import {useAppSelector} from "../redux/hooks";
import useCustomerActions from "./useCustomerActions"

const useInitialDataFetch = () => {
    const { getPendingEmployees: getEmployeesPendingActivation } = useEmployeeActivationActions()
    const { getPendingEmployees: getEmployeesPendingDeactivation } = useEmployeeDeactivationActions()
    const { getCustomer } = useCustomerActions()
    const { getEmployees } = useEmployeeActions()
    const { getPayrollData } = usePayrollActions()
    const { getFiles } = useFileActions()
    const user = useAppSelector(state => state.auth.authenticatedUser)
    const currentCustomerId = useAppSelector(state => state.auth.currentCustomerId)

    const fetchData = () => {
        getCustomer()
        getEmployees()
        getFiles()

        if (!user || !currentCustomerId) {
            return
        }

        if (userHasAuthorization(user, currentCustomerId, role => role.allowManageEmployeeActivations)) {
            getEmployeesPendingActivation()
            getEmployeesPendingDeactivation()
        }

        if (userHasAuthorization(user, currentCustomerId, role => role.allowManagePayroll)) {
            getPayrollData()
        }
    }

    return {
        fetchData
    }
}

export default useInitialDataFetch
