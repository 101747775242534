import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthenticationResult, CurrentUser } from '../types'

interface AuthUserState {
    loading: boolean
    isAuthenticated: boolean
    currentCustomerId: string | null
    authenticatedUser: CurrentUser | null
    regularLoginTasksHandled: boolean
    error: string | null
}

const initialState: AuthUserState = {
    loading: false,
    isAuthenticated: false,
    currentCustomerId: null,
    authenticatedUser: null,
    regularLoginTasksHandled: true,
    error: null
}

export const authUserSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
        },
        authenticationSuccess: (state, action: PayloadAction<AuthenticationResult>) => {
            state.loading = false
            state.isAuthenticated = true
            state.authenticatedUser = action.payload.data.user
            state.currentCustomerId = action.payload.data.currentCustomerId
            state.regularLoginTasksHandled = !action.payload.regularLogin
            state.error = null
        },
        authenticationFailure: (state, action: PayloadAction<string | null>) => {
            state.loading = false
            state.error = action.payload
        },
        authenticatedUserUpdated: (state, action: PayloadAction<CurrentUser>) => {
            console.log("authenticatedUserUpdated", JSON.stringify(action.payload, null, 2))
            state.loading = false
            state.authenticatedUser = action.payload
            state.error = null
        },
        authenticatedUserUpdatedFailure: (state, action: PayloadAction<string | null>) => {
            state.loading = false
            state.error = action.payload
        },
        logout: state => {
            state = {...initialState}
        },
        loginTasksHandled: state => {
            state.regularLoginTasksHandled = true
        }
    }
})

export const {
    loading,
    authenticationSuccess,
    authenticationFailure,
    authenticatedUserUpdated,
    authenticatedUserUpdatedFailure,
    logout,
    loginTasksHandled
} = authUserSlice.actions

export default authUserSlice.reducer
