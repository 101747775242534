import {useTranslation} from "react-i18next";
import {AuthenticationMethod} from "../authentication/AuthenticationMethod";
import {Image} from "react-bootstrap";
import BankID_logo from "../assets/BankID_logo.png";
import TextButton from "./TextButton";
import React from "react";
import {createUseStyles} from "react-jss";
import {Colors} from "../constants/colors";

interface AuthenticationMethodSelectorProps {
  onAuthenticationMethodSelected: (method: AuthenticationMethod) => void
  enablePasswordAuth?: boolean
}

export const AuthenticationMethodSelector = ({
                                               onAuthenticationMethodSelected,
                                               enablePasswordAuth
                                             }: AuthenticationMethodSelectorProps) => {
  const styles = useStyles()
  const {t} = useTranslation('login');

  return (
    <>
      <div className={styles.contentGroup}>
        <div className={styles.clickable} onClick={() => onAuthenticationMethodSelected(AuthenticationMethod.BankIdQr)}>
          <Image src={BankID_logo} fluid/>
          <span>{t("bankIdOnOtherDevice")}</span>
        </div>

        <div className={styles.clickable} onClick={() => onAuthenticationMethodSelected(AuthenticationMethod.BankId)}>
          <Image src={BankID_logo} fluid/>
          <span>{t("bankIdOnThisDevice")}</span>
        </div>
      </div>

      {enablePasswordAuth &&
          <div className={styles.contentGroup}>
              <TextButton onClick={() => onAuthenticationMethodSelected(AuthenticationMethod.UsernamePassword)}>
                {t('loginWithUsernameAndPassword')}
              </TextButton>
          </div>
      }
    </>
  )
}


const useStyles = createUseStyles({
  contentGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    gap: 20
  },
  clickable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: 120,
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Colors.grayBorder,
    padding: 10,
    borderRadius: 8,
    transition: '0.4s',
    '&:hover': {
      borderColor: Colors.brandGreen
    }
  }
})
