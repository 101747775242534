import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Customer, DayOfWeek } from '../types'

interface CustomerState {
    loading: boolean
    customer: Customer | null
    error: Error | string | null
}

const initialState: CustomerState = {
    loading: false,
    customer: null,
    error: null
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        customerFetched: (state, action: PayloadAction<Customer>) => {
            state.loading = false
            state.customer = action.payload
            state.error = null
        },
        customerFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoiceEmailAddressChanged: (state, action: PayloadAction<Customer>) => {
            state.loading = false
            state.customer = action.payload
            state.error = null
        },
        invoiceEmailAddressChangeFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        activationNotificationDaysOfWeekChanged: (state, action: PayloadAction<DayOfWeek[]>) => {
            state.loading = false
            state.customer = { ...state.customer, employer: { ...state.customer!.employer!, activationNotificationDaysOfWeek: action.payload } } as Customer
            state.error = null
        },
        activationNotificationDaysOfWeekChangeFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    customerFetched, 
    customerFetchFailure,
    invoiceEmailAddressChanged,
    invoiceEmailAddressChangeFailure,
    activationNotificationDaysOfWeekChanged,
    activationNotificationDaysOfWeekChangeFailure
} = customerSlice.actions

export default customerSlice.reducer