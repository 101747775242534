import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { Customer, DayOfWeek } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { 
    loading, 
    customerFetched, 
    customerFetchFailure,
    activationNotificationDaysOfWeekChanged, 
    activationNotificationDaysOfWeekChangeFailure, 
    invoiceEmailAddressChanged, 
    invoiceEmailAddressChangeFailure 
} from "../redux/slices/customerSlice";

const useCustomerActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getCustomer = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.customer)
            .then(response => {
                dispatch(customerFetched(response.data as Customer))
            })
            .catch(error => {
                console.log('getEmployer error:', error)
                dispatch(customerFetchFailure(error))
            })
    }

    const setActivationNotificationDaysOfWeek = (daysOfWeek: DayOfWeek[]) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.employer + 'activationNotifications', { daysOfWeek: daysOfWeek })
            .then(response => {
                dispatch(activationNotificationDaysOfWeekChanged(response.data as DayOfWeek[]))
            })
            .catch(error => {
                console.log('setActivationNotificationDaysOfWeek error:', error)
                dispatch(activationNotificationDaysOfWeekChangeFailure(error))
            })
    }

    const setInvoiceEmailAddress = (email: string) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.customer + 'invoiceEmailAddress', { invoiceEmailAddress: email })
            .then(response => {
                dispatch(invoiceEmailAddressChanged(response.data as Customer))
            })
            .catch(error => {
                console.log('setInvoiceEmailAddress error:', error)
                dispatch(invoiceEmailAddressChangeFailure(error))
            })
    }

    return {
        getCustomer,
        setActivationNotificationDaysOfWeek,
        setInvoiceEmailAddress
    }
}

export default useCustomerActions