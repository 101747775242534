const useDevice = () => {
  const isIosDevice = Boolean(navigator.userAgent.match(/(iPod|iPhone|iPad)/));
  const isEdgeOnIosDevice = Boolean(navigator.userAgent.match(/EdgiOS/));
  const isChromeOnIosDevice = Boolean(navigator.userAgent.match(/CriOS/));
  const isFirefoxOnIosDevice = Boolean(navigator.userAgent.match(/FxiOS/));

  return {
    isIosDevice,
    isEdgeOnIosDevice,
    isChromeOnIosDevice,
    isFirefoxOnIosDevice,
  };
};

export default useDevice;
