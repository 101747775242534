import {apiConfig} from "../apiConfig";


export const useAuthenticationClient = () => {
  const baseAuthUrl = apiConfig.baseUrlAuth

  const authenticate = async (body: AuthenticationRequest): Promise<AuthenticationResponse> => {
    return await fetch(baseAuthUrl + "auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }).then(response => {
      return response.json();
    })
  }

  const qr = async (orderRef: string): Promise<string> => {
    return await fetch(`${baseAuthUrl}auth/qr/${orderRef}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(response => {
      return response.text()
    })
  }

  const collect = async (body: CollectRequest): Promise<CollectResponse> => {
    return await fetch(baseAuthUrl + "auth/collect", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }).then(response => {
      return response.json();
    })
  }

  const refresh = async (refreshToken: string): Promise<RefreshAuthTokenResponse> => {
    return await fetch(baseAuthUrl + "auth/refresh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${refreshToken}`
      }
    }).then(response => {
      return response.json()
    })
  }

  return {
    authenticate,
    qr,
    collect,
    refresh
  }
}


//-----Types-----

export interface AuthenticationRequest {
  identificationNumber?: string | undefined;
  country?: string | undefined;
}

export interface AuthenticationResponse {
  orderRef: string
  autoStartToken: string
}

export interface QrRequest {
  orderRef: string;
}

export interface CollectRequest {
  orderRef: string;
}

export type CollectStatus = 'pending' | 'complete' | 'failed' | 'none'

export interface CollectResponse {
  status: CollectStatus
  hintCode: string
  token: string
  refreshToken: string
}

export interface RefreshAuthTokenResponse {
  token: string,
  refreshToken: string
}
