import {Location, Navigate, RouteObject} from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import CreatePassword from '../pages/password/CreatePassword'
import PasswordMailSent from '../pages/password/PasswordMailSent'
import SetPassword from '../pages/password/SetPassword'
import Login from '../pages/Login'
import NotFound from '../pages/NotFound'
import AuthenticatedLayout from '../pages/layout/AuthenticatedLayout'
import {ReactNode} from 'react'
import PortalUsers from '../pages/PortalUsers'
import CreditUsage from '../pages/CreditUsage'
import Employees from '../pages/Employees'
import Shifts from '../pages/Shifts'
import Activations from '../pages/Activations'
import IntegrationLog from '../pages/IntegrationLog'
import Payrolls from '../pages/Payrolls'
import CompanySettings from '../pages/CompanySettings'
import {CurrentUser, PortalRoleModel} from '../redux/types'
import {userHasAuthorization} from '../util/role'
import Deviations from '../pages/Deviations'
import PaySchedules from '../pages/PaySchedules'
import Files from '../pages/Files'
import Integrations from '../pages/integrations/Integrations'
import FortnoxActivation from '../pages/integrations/FortnoxActivation'
import PayoutBasis from '../pages/PayoutBasis'
import Deactivations from '../pages/Deactivations'
import PaymentNotifications from '../pages/PaymentNotifications'
import PlandayActivation from '../pages/integrations/PlandayActivation'
import AgiAdjustments from '../pages/AgiAdjustments'
import AgiAdjustmentDetails from '../pages/AgiAdjustmentDetails'
import AgiAdjustment from '../pages/AgiAdjustment'
import PayrollFileProcessor from "../pages/PayrollFileProcessor";
import ForaAdjustments from '../pages/ForaAdjustments'
import ForaAdjustmentDetails from '../pages/ForaAdjustmentDetails'
import ForaAdjustment from '../pages/ForaAdjustment'
import EmployeeImport from "../pages/EmployeeImport";
import EmployerInitiatedPayoutProcessor from "../pages/EmployerInitiatedPayoutProcessor";
import PortalRoles from "../pages/PortalRoles";
import BookkeepingSettings from '../pages/BookkeepingSettings'

const AuthenticatedRoute = (isAuthenticated: boolean, location: Location, children: ReactNode) => {
  return isAuthenticated
    ? <AuthenticatedLayout>{children}</AuthenticatedLayout>
    : <Navigate to="/login" replace state={{from: location}}/>
}

const AuthorizedRouteBuilder = (isAuthenticated: boolean, user: CurrentUser | null, currentCustomerId: string | null, location: Location) => {
  return (authorizationPredicate: ((role: PortalRoleModel) => boolean), children: ReactNode) => {
    if (!isAuthenticated) {
      return (
        <Navigate to="/login" replace state={{from: location}}/>
      )
    }

    if (user && currentCustomerId && userHasAuthorization(user, currentCustomerId, authorizationPredicate)) {
      return (
        <AuthenticatedLayout>{children}</AuthenticatedLayout>
      )
    }

    return (<Navigate to="/"/>)
  }
}

const routes = (isAuthenticated: boolean, user: CurrentUser | null, currentCustomerId: string | null, location: Location): RouteObject[] => {
  const authorizedRoute = AuthorizedRouteBuilder(isAuthenticated, user, currentCustomerId, location)

  return [
    {
      path: '/',
      element: AuthenticatedRoute(isAuthenticated, location, <Dashboard/>)
    },
    {
      path: '/creditusage',
      element: authorizedRoute(x => x.allowReadCreditReport, <CreditUsage/>)
    },
    {
      path: '/paySchedules',
      element: authorizedRoute(x => x.allowManagePaySchedules, <PaySchedules/>)
    },
    {
      path: '/employees',
      element: authorizedRoute(x => x.allowManageEmployees, <Employees/>)
    },
    {
      path: '/employees/import',
      element: authorizedRoute(x => x.allowManageEmployees, <EmployeeImport/>)
    },
    {
      path: '/shifts',
      element: authorizedRoute(x => x.allowManageShifts, <Shifts/>)
    },
    {
      path: '/deviations',
      element: authorizedRoute(x => x.allowManagePayDeviations, <Deviations/>)
    },
    {
      path: '/payoutBasis',
      element: authorizedRoute(x => x.allowManagePayroll, <PayoutBasis/>)
    },
    {
      path: '/agiAdjustment',
      element: authorizedRoute(x => x.allowManageAgi, <AgiAdjustments/>)
    },
    {
      path: '/agiAdjustment/create',
      element: authorizedRoute(x => x.allowManageAgi, <AgiAdjustment/>)
    },
    {
      path: '/agiAdjustment/:period',
      element: authorizedRoute(x => x.allowManageAgi, <AgiAdjustmentDetails/>)
    },
    {
      path: '/foraAdjustment',
      element: authorizedRoute(x => x.allowManageFora, <ForaAdjustments/>)
    },
    {
      path: '/foraAdjustment/create',
      element: authorizedRoute(x => x.allowManageFora, <ForaAdjustment/>)
    },
    {
      path: '/foraAdjustment/:period',
      element: authorizedRoute(x => x.allowManageFora, <ForaAdjustmentDetails/>)
    },
    {
      path: '/paymentNotifications',
      element: authorizedRoute(x => x.allowReadCreditReport, <PaymentNotifications/>)
    },
    {
      path: '/payroll',
      element: authorizedRoute(x => x.allowManagePayroll, <Payrolls/>)
    },
    {
      path: '/payroll/create',
      element: authorizedRoute(x => x.allowManagePayroll, <PayrollFileProcessor/>)
    },
    {
      path: '/payroll/createEmployerInitiatedPayout',
      element: authorizedRoute(x => x.allowManagePayroll, <EmployerInitiatedPayoutProcessor/>)
    },
    {
      path: '/activations',
      element: authorizedRoute(x => x.allowManageEmployeeActivations, <Activations/>)
    },
    {
      path: '/deactivations',
      element: authorizedRoute(x => x.allowManageEmployeeActivations, <Deactivations/>)
    },
    {
      path: '/files',
      element: AuthenticatedRoute(isAuthenticated, location, <Files/>)
    },
    {
      path: '/integrations/fortnox/activation',
      element: authorizedRoute(x => x.allowManageIntegrations, <FortnoxActivation/>)
    },
    {
      path: '/integrations/planday/activation',
      element: authorizedRoute(x => x.allowManageIntegrations, <PlandayActivation/>)
    },
    {
      path: '/integrations',
      element: authorizedRoute(x => x.allowManageIntegrations, <Integrations/>)
    },
    {
      path: '/integrationlog',
      element: authorizedRoute(x => x.allowViewIntegrationLog, <IntegrationLog/>)
    },
    {
      path: '/companysettings',
      element: authorizedRoute(x => x.allowManageCompanySettings, <CompanySettings/>)
    },
    {
      path: '/bookkeepingsettings',
      element: authorizedRoute(x => x.allowManageBookkeepingSettings, <BookkeepingSettings/>)
    },
    {
      path: '/portalusers',
      element: authorizedRoute(x => x.allowManageUsers, <PortalUsers/>)
    },
    {
      path: '/portalroles',
      element: authorizedRoute(x => x.allowManageUsers, <PortalRoles/>)
    },
    {
      path: '/login',
      element: !isAuthenticated ? <Login/> : <Navigate to="/"/>,
    },
    {
      path: '/forgotpassword',
      element: <CreatePassword/>,
    },
    {
      path: '/createpassword',
      element: <CreatePassword/>,
    },
    {
      path: '/passwordmailsent',
      element: <PasswordMailSent/>,
    },
    {
      path: '/setpassword',
      element: <SetPassword/>,
    },
    {
      path: '*',
      element: <NotFound/>,
    }
  ]
}

export default routes
