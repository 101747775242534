import {useEffect, useMemo, useState} from "react"
import {createUseStyles} from 'react-jss'
import ConfirmModal from "../components/ConfirmModal"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import usePortalUserActions from "../hooks/usePortalUserActions"
import {CreateOrUpdatePortalRoleModel, PortalRoleModel} from "../redux/types"
import Button from '../components/Button'
import {useAppSelector} from "../redux/hooks"
import Card from "../components/Card"
import {useTranslation} from "react-i18next"
import usePortalRolesActions from "../hooks/usePortalRolesActions";
import PortalRoleCard from "../components/PortalRoles/PortalRoleCard";
import EditPortalRoleModal from "../components/PortalRoles/EditPortalRoleModal";

const PortalRoles = () => {
  const styles = useStyles()
  const {t} = useTranslation('portalRoles');

  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState<boolean>(false)
  const [deleteCandidate, setDeleteCandidate] = useState<PortalRoleModel>()

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
  const [editPortalRoleModel, setEditPortalRoleModel] = useState<PortalRoleModel>()

  const {getUsers} = usePortalUserActions()
  const usersLoading = useAppSelector(state => state.portalUser.loading)
  const portalUsers = useAppSelector(state => state.portalUser.portalUsers)

  const portalRoles = useAppSelector(state => state.portalRoles.portalRoles)
  const rolesLoading = useAppSelector(state => state.portalRoles.loading)
  const {getRoles, createRole, updateRole, deleteRole} = usePortalRolesActions()

  const sortedPortalRoles = useMemo(() => [...portalRoles].sort((a, b) => a.name.localeCompare(b.name)), [portalRoles])

  useEffect(() => {
    if (portalUsers.length === 0) {
      getUsers()
    }
  }, [])

  useEffect(() => {
    if (portalRoles.length === 0) {
      getRoles()
    }
  }, []);

  const onAddClick = () => {
    setEditPortalRoleModel(undefined)
    setEditModalVisible(true)
  }

  const onEditClick = (portalRole: PortalRoleModel) => {
    setEditPortalRoleModel(portalRole)
    setEditModalVisible(true)
  }

  const handlePortalRoleEdited = (createOrUpdatePortalRoleModel: CreateOrUpdatePortalRoleModel) => {
    if (editPortalRoleModel) {
      updateRole(editPortalRoleModel.id, createOrUpdatePortalRoleModel)
      setEditPortalRoleModel(undefined)
      setEditModalVisible(false)
    }
    else {
      createRole(createOrUpdatePortalRoleModel)
      setEditModalVisible(false)
    }
  }

  const handlePortalRoleEditCancelled = () => {
    setEditPortalRoleModel(undefined)
    setEditModalVisible(false)
  }

  const onDeleteClick = (portalRole: PortalRoleModel) => {
    setDeleteCandidate(portalRole)
    setConfirmDeleteModalVisible(true)
  }

  const handleDeleteConfirmed = () => {
    if (deleteCandidate) {
      deleteRole(deleteCandidate.id)
      setDeleteCandidate(undefined)
    }
    setConfirmDeleteModalVisible(false)
  }

  return (
    <div className={styles.container}>
      <Card>
        <h2>
          {t('title')}
        </h2>
      </Card>

      <Card className={styles.buttonCard}>
        <Button onClick={onAddClick} title={t('addPortalRole')}/>
      </Card>

      {sortedPortalRoles.map(role =>
        <PortalRoleCard
          key={role.id}
          portalRole={role}
          employerPortalUsers={portalUsers}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      )}

      <FullScreenLoadingIndicator visible={usersLoading || rolesLoading}/>

      <EditPortalRoleModal
        portalRole={editPortalRoleModel}
        visible={editModalVisible}
        onConfirmClick={handlePortalRoleEdited}
        onCancelClick={handlePortalRoleEditCancelled}
      />

      <ConfirmModal
        visible={confirmDeleteModalVisible}
        confirmText={t('confirmDeletePortalRole', {ns: 'portalRoles'})}
        onConfirmClick={handleDeleteConfirmed}
        onCancelClick={() => setConfirmDeleteModalVisible(false)}
      />
    </div>
  )
}

export default PortalRoles

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  buttonCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})
