import React, {useState} from "react"
import {createUseStyles} from 'react-jss'
import InputField from '../InputField'
import Button from '../Button'
import {faEnvelope, faKey} from '@fortawesome/free-solid-svg-icons'
import {Link, useLocation} from "react-router-dom"
import useAuthUserActions from "../../hooks/auth/useAuthUserActions"
import {useAppSelector} from '../../redux/hooks'
import {Colors} from "../../constants/colors"
import {useTranslation} from "react-i18next"
import FullScreenLoadingIndicator from "../FullScreenLoadingIndicator"


const UsernamePasswordLogin = () => {
  const loading = useAppSelector(state => state.auth.loading)
  const error = useAppSelector(state => state.auth.error)
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const {login} = useAuthUserActions()
  const {t} = useTranslation(['common', 'login']);
  const location = useLocation()

  const handleFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (email && password)
      login(email, password, location?.state?.from)
  }

  const renderErrorMessage = () => {
    if (error === '401') {
      return t('loginFailureIncorrectCredentials', {ns: 'login'})
    }
    return t('loginFailure', {ns: 'login'})
  }

  const styles = useStyles()

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className={styles.cardRow}>
          <InputField
            id='email'
            label={t('email', {ns: 'common'})}
            type='email'
            icon={faEnvelope}
            onChange={setEmail}
          />
        </div>
        <div className={styles.cardRow}>
          <InputField
            id='password'
            label={t('password', {ns: 'common'})}
            type='password'
            icon={faKey}
            onChange={setPassword}
          />
        </div>
        <div className={`${styles.cardRow} ${styles.buttonRow}`}>
          <div>
            <Link to={'/forgotpassword'}>{t('forgotPassword', {ns: 'login'})}</Link>
          </div>
          <div className={styles.button}>
            <Button
              type='submit'
              disabled={!(email && password)}
              title={t('loginButtonText', {ns: 'login'})}
            />
          </div>
        </div>
        {!loading && error &&
            <div className={styles.cardRow}>
                <label className={styles.errorLabel}>
                  {renderErrorMessage()}
                </label>
            </div>}
      </form>
      <FullScreenLoadingIndicator visible={loading}/>
    </>
  )
}

export default UsernamePasswordLogin

const useStyles = createUseStyles({
  title: {
    marginBottom: 30,
    textAlign: 'center'
  },
  cardRow: {
    marginTop: 10,
    marginBottom: 10,
    width: 350
  },
  buttonRow: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  errorLabel: {
    color: Colors.rubel_700
  },
  clickableText: {
    cursor: 'pointer',
    color: Colors.peso_700,
    '&:hover': {
      color: Colors.peso_800
    }
  }
})
