import {createUseStyles} from 'react-jss'
import InfoIcon from "../InfoIcon";
import {useTranslation} from "react-i18next";
import Switch from "../Switch";
import {
  BookkeepingAccountType,
  BookkeepingAccountTypeConfiguration,
  BookkeepingAccountTypeRule,
} from "../../redux/types";
import BookkeepingAccountTypeRuleList from './BookkeepingAccountTypeRuleList';
import Card from '../Card';
import { Colors } from '../../constants/colors';

interface Props {
  accountTypeConfig: BookkeepingAccountTypeConfiguration
  onChange: (config: BookkeepingAccountTypeConfiguration) => void
}

const BookkeepingAccountTypeComponent = ({accountTypeConfig, onChange}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'bookkeepingSettings']);

  const onSettingChanged = (field: string, value: any) => {
    onChange({...accountTypeConfig, [field]: value})
  }

  const onRuleChange = (rules: BookkeepingAccountTypeRule[]) => {
    onChange({...accountTypeConfig, rules: rules})
  }

  const getTitle = (accountType: BookkeepingAccountType) => {
    return t(`accountType_${accountType}`, {ns: 'bookkeepingSettings'})
  }

  const getHelp = (accountType: BookkeepingAccountType) => {
    return t(`accountType_${accountType}_description`, {ns: 'bookkeepingSettings'})
  }

  return (
    <Card className={styles.container}>
      <div className={styles.titleContainer}>
        <b>{getTitle(accountTypeConfig.accountType)} <InfoIcon text={getHelp(accountTypeConfig.accountType)}/></b>
      </div>
      <div>
        <Switch
          checked={accountTypeConfig.includeCostCenterDimension}
          label={t('includeCostCenter', {ns: 'bookkeepingSettings'})}
          onChange={() => onSettingChanged('includeCostCenterDimension', !accountTypeConfig.includeCostCenterDimension)}
        />
        <Switch
          checked={accountTypeConfig.includeEmploymentDimension}
          label={t('includeEmployment', {ns: 'bookkeepingSettings'})}
          onChange={() => onSettingChanged('includeEmploymentDimension', !accountTypeConfig.includeEmploymentDimension)}
        />
        <Switch
          checked={accountTypeConfig.includeProjectDimension}
          label={t('includeProject', {ns: 'bookkeepingSettings'})}
          onChange={() => onSettingChanged('includeProjectDimension', !accountTypeConfig.includeProjectDimension)}
        />
      </div>
      <div className={styles.ruleContainer}>
        <BookkeepingAccountTypeRuleList rules={accountTypeConfig.rules} onChange={onRuleChange} />
      </div>
    </Card>
  )
}

export default BookkeepingAccountTypeComponent

const useStyles = createUseStyles({
  container: {
    marginTop: 10,
    backgroundColor: Colors.grayBackground
  },
  titleContainer: {
    marginBottom: 10
  },
  ruleContainer: {
    marginTop: 20
  }
})
  