import { createUseStyles } from "react-jss"
import Card from "../components/Card"
import InfoCard from "../components/InfoCard"
import { useTranslation } from "react-i18next"
import BookkeepingComponent from "../components/Bookkeeping/BookkeepingComponent"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import { useAppSelector } from "../redux/hooks"

const BookkeepingSettings = () => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'bookkeepingSettings']);
    const bookkeepingSettingsLoading = useAppSelector(state => state.bookkeepingConfiguration.loading)

    return (
        <>
          <Card className={styles.card}>
            <h2>
              {t('title', {ns: 'bookkeepingSettings'})}
            </h2>
          </Card>
          <div className={styles.infoCardContainer}>
            <InfoCard title={t('bookkeepingSettings', {ns: 'common'})} className={styles.infoCard}>
              <BookkeepingComponent />
            </InfoCard>
          </div>
          <FullScreenLoadingIndicator visible={bookkeepingSettingsLoading}/>
        </>
      )
}

export default BookkeepingSettings

const useStyles = createUseStyles({
    card: {
      marginBottom: 20
    },
    infoCard: {
      flex: 1
    },
    infoCardContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      columnGap: 20
    }
  })
  