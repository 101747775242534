import {
  faBook,
  faCalendar,
  faCog,
  faCogs,
  faCoins,
  faFile,
  faFileInvoiceDollar,
  faMinusSquare,
  faStopwatch,
  faTachometerAlt,
  faUserCheck,
  faUsers,
  faUsersCog,
  faUserTimes
} from '@fortawesome/free-solid-svg-icons'
import {createUseStyles} from 'react-jss'
import {headerHeight} from '../../../constants/dimensons'
import SidebarLink from './SidebarLink'
import logo from '../../../assets/images/logo/cappy-symbol-pos.png'
import {Colors} from '../../../constants/colors'
import {useAppSelector} from '../../../redux/hooks'
import {Link, useLocation} from 'react-router-dom'
import {Fonts} from '../../../constants/fonts'
import {useTranslation} from 'react-i18next'
import Emitter from '../../../eventing/Emitter'
import {Events} from '../../../eventing/Events'
import {PayoutBasisFileType} from '../../../redux/types'
import {truncateString} from '../../../util/string'
import {RenderForPolicy} from "../../RenderForPolicy";


const Sidebar = () => {
  const styles = useStyles()
  const location = useLocation();
  const {t} = useTranslation('common');

  const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
  const employeesPendingActivation = useAppSelector(state => state.employeeActivation.pendingEmployees)
  const employeesPendingDeactivation = useAppSelector(state => state.employeeDeactivation.pendingEmployees)
  const upcomingPayrolls = useAppSelector(state => state.payroll.upcomingPayrolls)
  const customer = useAppSelector(state => state.customer.customer)
  const files = useAppSelector(state => state.files.files)

  const getDuePayrollReportCount = () => {
    return upcomingPayrolls.filter(x => x.payrollFileDue || x.validationErrors?.length > 0).length;
  }

  const getActivationTodoCount = () => {
    return employeesPendingActivation.filter(x => x.bankAccountActivationFailed || !x.pendingBankAccountActivation).length;
  }

  const getDeactivationTodoCount = () => {
    return employeesPendingDeactivation.length;
  }

  const getNewFileCount = () => {
    return files.filter(x => (x.payrollSystemPayoutBasisImportFile?.lastDownloadDate ?? x.employerPayDeductionPaymentNotificationFile?.lastDownloadDate ?? x.creditReportFile?.lastDownloadDate) == null).length;
  }

  const getNewPayoutBasisFileCount = () => {
    return files.filter(x => x.payrollSystemPayoutBasisImportFile?.fileType === PayoutBasisFileType.payoutBasisForCalendarMonth && x.payrollSystemPayoutBasisImportFile?.lastDownloadDate == null).length;
  }

  const getNewPaymentNotificationFileCount = () => {
    return files.filter(x => x.employerPayDeductionPaymentNotificationFile != null && x.employerPayDeductionPaymentNotificationFile.lastDownloadDate == null).length;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.logoContainer}>
            <Link to="/"><img className={styles.logo} src={logo} alt=''/></Link>
          </div>
          <div className={styles.loggedInUserContainer}>
            <div className={styles.loggedInUser} onClick={() => Emitter.emit(Events.displayProfile)}>
              {truncateString(`${authenticatedUser?.givenName} ${authenticatedUser?.familyName}`, 26)}
            </div>
            <div className={styles.company} title={customer?.legalName}>
              {truncateString(customer?.legalName ?? '', 26)}
            </div>
          </div>
        </div>
        <div className={styles.mainContainer}>
          <h6 className={styles.sectionTitle}>{t('start')}</h6>
          <SidebarLink title={t('dashboard')} icon={faTachometerAlt} linkTo='/' active={location.pathname === '/'}/>

          <RenderForPolicy policy={"allowReadCreditReport"}>
            <h6 className={styles.sectionTitle}>{t('reports')}</h6>
            <SidebarLink
              title={t('creditUsage')}
              icon={faCoins}
              linkTo='/creditusage'
              active={location.pathname.startsWith('/creditusage')}/>
          </RenderForPolicy>

          <h6 className={styles.sectionTitle}>{t('manage')}</h6>

          <RenderForPolicy policy={"allowManageEmployeeActivations"}>
            {customer?.employer?.employeeActivationType !== 'automatic' &&
                <SidebarLink title={t('activations')} icon={faUserCheck} linkTo='/activations'
                             dotNumber={getActivationTodoCount()}
                             active={location.pathname.startsWith('/activations')}/>
            }
            {customer?.employer?.employeeDeactivationType === 'manual' &&
                <SidebarLink title={t('deactivations')} icon={faUserTimes} linkTo='/deactivations'
                             dotNumber={getDeactivationTodoCount()}
                             active={location.pathname.startsWith('/deactivations')}/>
            }
          </RenderForPolicy>

          <RenderForPolicy policy={"allowManageEmployees"}>
            <SidebarLink title={t('employees')} icon={faUsers} linkTo='/employees'
                         active={location.pathname.startsWith('/employees')}/>
          </RenderForPolicy>

          <RenderForPolicy policy={"allowManageShifts"}>
            <SidebarLink title={t('shifts')} icon={faStopwatch} linkTo='/shifts'
                         active={location.pathname.startsWith('/shifts')}/>
          </RenderForPolicy>

          <RenderForPolicy policy={"allowManagePayDeviations"}>
            <SidebarLink title={t('deviations')} icon={faMinusSquare} linkTo='/deviations'
                         active={location.pathname.startsWith('/deviations')}/>
          </RenderForPolicy>

          <RenderForPolicy policy={"allowManagePaySchedules"}>
            <SidebarLink title={t('paySchedules')} icon={faCalendar} linkTo='/paySchedules'
                         active={location.pathname.startsWith('/paySchedules')}/>
          </RenderForPolicy>

          {customer?.employer?.adminFlowType === 'agiAdjustment' &&
              <>
                  <RenderForPolicy policy={"allowManageAgi"}>
                      <SidebarLink title={t('agiAdjustment')} icon={faFileInvoiceDollar} linkTo='/agiAdjustment'
                                   active={location.pathname.startsWith('/agiAdjustment')}/>

                  </RenderForPolicy>
                  <RenderForPolicy policy={"allowManageFora"}>
                      <SidebarLink title={t('foraAdjustment')} icon={faFileInvoiceDollar}
                                   linkTo='/foraAdjustment'
                                   active={location.pathname.startsWith('/foraAdjustment')}/>
                  </RenderForPolicy>
              </>
          }

          <RenderForPolicy policy={"allowManagePayroll"}>
            {customer?.employer?.adminFlowType === 'basisImport' &&
                <SidebarLink title={t('payoutBasis')} icon={faFileInvoiceDollar} linkTo='/payoutBasis'
                             dotNumber={getNewPayoutBasisFileCount()}
                             active={location.pathname.startsWith('/payoutBasis')}/>
            }
            <SidebarLink title={t('payroll')} icon={faFileInvoiceDollar} linkTo='/payroll'
                         dotNumber={getDuePayrollReportCount()}
                         active={location.pathname.startsWith('/payroll')}/>
          </RenderForPolicy>

          {customer?.employer?.adminFlowType === 'basisImport' &&
              <RenderForPolicy policy={"allowReadCreditReport"}>
                  <SidebarLink title={t('paymentNotifications')} icon={faFileInvoiceDollar}
                               linkTo='/paymentNotifications' dotNumber={getNewPaymentNotificationFileCount()}
                               active={location.pathname.startsWith('/paymentNotifications')}/>
              </RenderForPolicy>}


          <h6 className={styles.sectionTitle}>{t('files')}</h6>
          <SidebarLink title={t('files')} icon={faFile} linkTo='/files' dotNumber={getNewFileCount()}
                       active={location.pathname.startsWith('/files')}/>

          <RenderForPolicy policy={["allowViewIntegrationLog", "allowManageIntegrations"]}>
            <h6 className={styles.sectionTitle}>{t('integration')}</h6>
            {(customer?.portalIntegrations?.length ?? 0) > 0 &&
                <RenderForPolicy policy={"allowManageIntegrations"}>
                    <SidebarLink title={t('integration')} icon={faCogs} linkTo='/integrations'
                                 active={location.pathname.startsWith('/integrations')}/>
                </RenderForPolicy>
            }
            <RenderForPolicy policy={"allowViewIntegrationLog"}>
              <SidebarLink title={t('log')} icon={faCogs} linkTo='/integrationlog'
                           active={location.pathname.startsWith('/integrationlog')}/>
            </RenderForPolicy>
          </RenderForPolicy>

          <RenderForPolicy
            policy={["allowManageCompanySettings", "allowManageUsers", "allowManageBookkeepingSettings"]}>
            <h6 className={styles.sectionTitle}>{t('settings')}</h6>

            <RenderForPolicy policy={"allowManageCompanySettings"}>
              <SidebarLink title={t('companySettings')} icon={faCog} linkTo='/companysettings'
                           active={location.pathname.startsWith('/companysettings')}/>
            </RenderForPolicy>

            {customer?.employer?.adminFlowType == 'agiAdjustment' &&
                <RenderForPolicy policy={"allowManageBookkeepingSettings"}>
                    <SidebarLink title={t('bookkeepingSettings')} icon={faBook} linkTo='/bookkeepingsettings'
                                 active={location.pathname.startsWith('/bookkeepingsettings')}/>
                </RenderForPolicy>}

            <RenderForPolicy policy={"allowManageUsers"}>
              <SidebarLink title={t('portalUsers')} icon={faUsers} linkTo='/portalusers'
                           active={location.pathname.startsWith('/portalusers')}/>
              <SidebarLink title={t('portalRoles')} icon={faUsersCog} linkTo={'/portalroles'}
                           active={location.pathname.startsWith('/portalroles')}/>
            </RenderForPolicy>
          </RenderForPolicy>
        </div>
      </div>
    </>
  )
}

export default Sidebar

const useStyles = createUseStyles({
  container: {
    color: Colors.gray1,
  },
  headerContainer: {
    position: 'relative',
    height: headerHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 10,
    paddingLeft: 30
  },
  mainContainer: {
    zIndex: 0,
    paddingTop: 20,
    paddingBottom: headerHeight,
    backgroundColor: '#ffffff',
    overflowY: 'auto',
    height: '100vh'
  },
  sectionTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 10,
    textTransform: 'uppercase',
    fontSize: 15
  },
  logo: {
    width: 50
  },
  loggedInUserContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  logoContainer: {
    padding: 3
  },
  loggedInUser: {
    color: Colors.euro_800,
    fontFamily: Fonts.semiBold,
    cursor: 'pointer'
  },
  company: {
    color: Colors.gray3,
    fontSize: 13
  }
})
