import {useEffect} from 'react'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import InfoIcon from "../InfoIcon";
import EditableField from "../EditableField";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/hooks";
import Switch from "../Switch";
import useBookkeepingConfigurationActions from "../../hooks/useBookkeepingConfigurationActions";
import {
  BookkeepingAccountTypeConfiguration,
  BookkeepingConfiguration,
} from "../../redux/types";
import ListItemCard from "../ListItemCard";
import BookkeepingAccountTypeComponent from './BookkeepingAccountTypeComponent';

const BookkeepingComponent = () => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'bookkeepingSettings']);

  const {getBookkeepingConfiguration, updateBookkeepingConfiguration} = useBookkeepingConfigurationActions()
  const bookkeepingConfiguration = useAppSelector(state => state.bookkeepingConfiguration.bookkeepingConfiguration)

  useEffect(() => {
    if (bookkeepingConfiguration === null) {
      getBookkeepingConfiguration()
    }
  }, [])

  const onConfigurationChanged = (field: string, value: any) => {
    if (!bookkeepingConfiguration) return

    let newConfig = {...bookkeepingConfiguration, [field]: value}
    updateBookkeepingConfiguration(newConfig as BookkeepingConfiguration)
  }

  const onBookkeepingAccountTypeConfigurationChanged = (settings: BookkeepingAccountTypeConfiguration) => {
    if (!bookkeepingConfiguration) return

    const newAccountTypeConfigurations = [...bookkeepingConfiguration.accountTypeConfigurations]

    const index = newAccountTypeConfigurations.findIndex(x => x.accountType === settings.accountType)
    if (index === -1) return

    newAccountTypeConfigurations[index] = settings
    onConfigurationChanged('accountTypeConfigurations', newAccountTypeConfigurations)
  }

  const renderAccountTypeConfigurations = () => {
    if (!bookkeepingConfiguration?.accountTypeConfigurations) return null

    return bookkeepingConfiguration.accountTypeConfigurations
      .map((settings, index) => {
        return (
            <ListItemCard key={`accountType${index}`}>
              <BookkeepingAccountTypeComponent
                accountTypeConfig={settings}
                onChange={onBookkeepingAccountTypeConfigurationChanged}
              />
            </ListItemCard>
        )
      })
  }


  return (
    <div>
      <div className={styles.row}>
        <div className={styles.label}>
          {t('verificationDescription', {ns: 'bookkeepingSettings'})}
          <InfoIcon text={t('verificationDescriptionDescription', {ns: 'bookkeepingSettings'})}/>
        </div>
        <EditableField
          onEdited={(value: string) => onConfigurationChanged('verificationDescription', value)}
          value={bookkeepingConfiguration?.verificationDescription}
          validationRegex={/^.{0,500}$/}
          placeholder={t('notProvided', {ns: 'common'})}
        />
      </div>

      <div className={styles.row}>
        <div className={styles.label}>
          {t('verificationsPerIndividual', {ns: 'bookkeepingSettings'})}
          <InfoIcon text={t('verificationsPerIndividualDescription', {ns: 'bookkeepingSettings'})}/>
        </div>
        <Switch
          checked={bookkeepingConfiguration?.verificationsPerIndividual ?? false}
          label={t('verificationsPerIndividualSwitchLabel', {ns: 'bookkeepingSettings'})}
          onChange={() => onConfigurationChanged('verificationsPerIndividual', !(bookkeepingConfiguration?.verificationsPerIndividual ?? false))}
        />
      </div>

      <div className={styles.accountGroupContainer}>
        <h5>{t('bookkeepingAccounts', {ns: 'bookkeepingSettings'})}</h5>
        {renderAccountTypeConfigurations()}
      </div>
    </div>
  )
}

export default BookkeepingComponent

const useStyles = createUseStyles({
  label: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
    textTransform: 'uppercase',
    fontSize: 13,
    color: Colors.gray3
  },
  row: {
    flex: 1,
    marginBottom: 10,
  },
  accountGroupContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    rowGap: 10,
  }
})
