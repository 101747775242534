import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PortalRoleModel} from '../types'

interface PortalRolesState {
  loading: boolean
  portalRoles: PortalRoleModel[]
  error: Error | string | null
}

const initialState: PortalRolesState = {
  loading: false,
  portalRoles: [],
  error: null
}

export const portalRolesSlice = createSlice({
  name: 'portalRoles',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    portalRolesFetched: (state, action: PayloadAction<PortalRoleModel[]>) => {
      state.loading = false
      state.portalRoles = action.payload
      state.error = null
    },
    portalRolesFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    portalRoleCreated: (state, action: PayloadAction<PortalRoleModel>) => {
      state.loading = false
      state.portalRoles = [action.payload, ...state.portalRoles]
      state.error = null
    },
    portalRoleCreateFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    portalRoleUpdated: (state, action: PayloadAction<PortalRoleModel>) => {
      const portalRolesClone = [...state.portalRoles]
      const indexToUpdate = portalRolesClone.findIndex(x => x.id === action.payload.id)
      portalRolesClone[indexToUpdate] = action.payload

      state.loading = false
      state.portalRoles = portalRolesClone
      state.error = null
    },
    portalRoleUpdateFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    portalRoleDeleted: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.portalRoles = state.portalRoles.filter(x => x.id !== action.payload)
      state.error = null
    },
    portalRoleDeleteFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const {
  loading,
  portalRolesFetched,
  portalRolesFetchFailure,
  portalRoleCreated,
  portalRoleCreateFailure,
  portalRoleUpdated,
  portalRoleUpdateFailure,
  portalRoleDeleted,
  portalRoleDeleteFailure
} = portalRolesSlice.actions

export default portalRolesSlice.reducer
