import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {createUseStyles} from "react-jss";
import Modal from "./Modal"
import ListItemCard from "./ListItemCard";
import {useAppSelector} from "../redux/hooks";
import useEmployeeActions from "../hooks/useEmployeeActions";
import {Spinner} from "react-bootstrap";
import {formatDateString} from "../util/date";

interface Props {
  onCloseClick: () => void
  visible: boolean
}

const NewEmployeesWithPayoutsModal = ({onCloseClick, visible}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['payoutBasis', 'common']);
  const [textCopied, setTextCopied] = useState(false)

  const customer = useAppSelector(state => state.customer.customer)
  const newEmployeesWithPayouts = useAppSelector(state => state.employee.newEmployeesWithPayouts)
  const loading = useAppSelector(state => state.employee.loading)

  const { getNewEmployeesWithPayouts: getNewEmployees } = useEmployeeActions()

  useEffect(() => {
    if (visible) {
      getNewEmployees()
    }
  }, [visible])

  useEffect(() => {
    setTextCopied(false)
  }, [visible])

  const onCopyRows = () => {
    const employeeTextItems = newEmployeesWithPayouts?.employees?.map(employee => {
      return `${employee.externalId} ${employee.identificationNumber} ${employee.givenName} ${employee.familyName}`
    })
    if (!employeeTextItems) return
    navigator.clipboard.writeText(employeeTextItems.join('\n'))
    setTextCopied(true)
  }

  const renderHeader = () => {
    return (
      <ListItemCard className={styles.listItemContainer}>
        <div className={styles.smallCellContainer}>
          <strong>{t('id', {ns: 'common'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('idNumber', {ns: 'common'})}</strong>
        </div>
        <div className={styles.nameCellContainer}>
          <strong>{t('name', {ns: 'common'})}</strong>
        </div>
      </ListItemCard>
    )
  }

  const renderListItems = () => {
    return newEmployeesWithPayouts?.employees?.map((employee, index) => {
      return (
        <ListItemCard key={`employee${index}`} className={styles.listItemContainer} index={index}>
          <div className={styles.smallCellContainer}>
            {employee.externalId}
          </div>
          <div className={styles.defaultCellContainer}>
            {employee.identificationNumber}
          </div>
          <div className={styles.nameCellContainer}>
            {employee.givenName} {employee.familyName}
          </div>
        </ListItemCard>
      )
    })
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Spinner
          as='span'
          animation='border'
          size='sm'
        />
      )
    }

    if (!newEmployeesWithPayouts?.startDate) {
      var earliestPayoutBasisGenerationDay = customer?.employer?.calenderMonthPayoutBasisReportGenerationDayOfMonth ?? 0
      return (
        <p>
          {earliestPayoutBasisGenerationDay > 0 
            ? t('employeesWithPayoutsNotPossible_DayOfMonth', {ns: 'payoutBasis', dayOfMonth: earliestPayoutBasisGenerationDay})
            : t('employeesWithPayoutsNotPossible_MonthEnd', {ns: 'payoutBasis'})
          }
          </p>
      )
    }

    return (
      <>
        {renderHeader()}
        {renderListItems()}
      </>
    )
  }

  const title = newEmployeesWithPayouts?.startDate
    ? `${t('newEmployees', {ns: 'payoutBasis'})} ${formatDateString(newEmployeesWithPayouts.startDate)} - ${formatDateString(newEmployeesWithPayouts.endDate)}`
    : t('newEmployees', {ns: 'payoutBasis'})

  const hasRows = newEmployeesWithPayouts?.employees?.length ?? 0 > 0
  const copyButtonText = textCopied
    ? t('copiedRows', {ns: 'payoutBasis'})
    : t('copyRows', {ns: 'payoutBasis'})

  return (
    <Modal
      title={title}
      visible={visible}
      okButtonTitle={t('close', {ns: 'common'})}
      onHide={() => onCloseClick()}
      onOkClick={() => onCloseClick()}
      cancelButtonTitle={copyButtonText}
      onCancelClick={() => onCopyRows()}
      hideCancelButton={!hasRows}
      size={"lg"}
    >
      {renderContent()}
    </Modal>
  )
}

export default NewEmployeesWithPayoutsModal

const useStyles = createUseStyles({
  listItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  smallCellContainer: {
    width: 100
  },
  defaultCellContainer: {
    width: 140
  },
  nameCellContainer: {
    width: 200
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 30
  }
})
