import React, {useEffect} from "react"
import {createUseStyles} from 'react-jss'
import UsernamePasswordLogin from "../components/Authentication/UsernamePasswordLogin";
import {AuthenticationMethod} from "../authentication/AuthenticationMethod";
import Card from "../components/Card";
import {useTranslation} from "react-i18next";
import QrBankIdLogin from "../components/Authentication/QrBankIdLogin";
import BankIdLogin from "../components/Authentication/BankIdLogin";
import TextButton from "../components/TextButton";
import {Breakpoints} from "../constants/breakpoints";
import useAuthUserActions from "../hooks/auth/useAuthUserActions";
import {useLocation} from "react-router-dom";
import {AuthenticationMethodSelector} from "../components/AuthenticationMethodSelector";
import useHashParam from "../authentication/useHashParam";


const Login = () => {
  const {t, i18n} = useTranslation('login');
  const styles = useStyles()
  const {exchange} = useAuthUserActions()
  const location = useLocation()
  const {hashParam, setHashParam} = useHashParam('auth')

  const authenticationMethod = hashParam as AuthenticationMethod | undefined
  const setAuthenticationMethod = (method: AuthenticationMethod | undefined) => {
    setHashParam(method?.toString())
  }

  useEffect(() => {
    const browserLang = navigator.language
    i18n.changeLanguage(browserLang)
  }, []);

  const exchangeBankIdToken = async (token: string) => {
    await exchange(token, location?.state?.from)
  }

  const renderMethod = () => {
    switch (authenticationMethod) {
      case AuthenticationMethod.UsernamePassword:
        return <UsernamePasswordLogin/>
      case AuthenticationMethod.BankIdQr:
        return <QrBankIdLogin onAuthenticated={exchangeBankIdToken}/>
      case AuthenticationMethod.BankId:
        return <BankIdLogin onAuthenticated={exchangeBankIdToken}/>
      default:
        return <AuthenticationMethodSelector onAuthenticationMethodSelected={setAuthenticationMethod}
                                             enablePasswordAuth/>
    }
  }

  const renderTitle = () => {
    switch (authenticationMethod) {
      case AuthenticationMethod.UsernamePassword:
        return <h3>{t('loginWithUsernameAndPassword')}</h3>
      case AuthenticationMethod.BankIdQr:
        return <h3>{t('loginWithBankId')}</h3>
      case AuthenticationMethod.BankId:
        return <h3>{t('loginWithBankId')}</h3>
      default:
        return <h3>{t('selectLoginMethod')}</h3>
    }
  }

  return (
    <div className={styles.container}>
      <h1 style={{textAlign: 'center'}}>{t('title')}</h1>
      <Card className={styles.card}>
        {renderTitle()}
        {renderMethod()}

        {!!authenticationMethod &&
            <div className={styles.cardFooter}>
                <TextButton onClick={() => setAuthenticationMethod(undefined)}>{t('back')}</TextButton>
            </div>
        }
      </Card>
      <Card className={styles.card} style={{paddingLeft: 70, paddingRight: 70}}>
        <span>{t('contactCustomerServiceIntro', {ns: 'login'})} <a
          href="https://www.cappy.se/kundservice">{t('customerService', {ns: 'login'})}</a> {t('contactCustomerServiceOutro', {ns: 'login'})}</span>
      </Card>
    </div>
  )
}

export default Login

const useStyles = createUseStyles({
  container: {
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    gap: 30
  },
  card: {
    width: 500,
    [`@media (max-width: ${Breakpoints.tablet}px)`]: {
      width: '100%'
    },
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-end',
    gap: 10
  }
})
