import {useEffect, useRef, useState} from 'react';
import {CollectResponse, useAuthenticationClient} from './useAuthenticationClient';
import useDevice from "./useDevice";
import useHashParam from "./useHashParam";


export enum BankIdStatus {
  Initializing = 'initializing',
  Collecting = 'collecting',
  Success = 'success',
  Failure = 'failure'
}

export const useBankIdAuth = () => {
  const [qrCode, setQrCode] = useState<string>();
  const [hintCode, setHintCode] = useState<string>();
  const [autostartUrl, setAutostartUrl] = useState<string>()
  const [status, setStatus] = useState<BankIdStatus>()
  const {authenticate: auth, qr, collect} = useAuthenticationClient();
  const {isIosDevice, isEdgeOnIosDevice, isChromeOnIosDevice, isFirefoxOnIosDevice} = useDevice()
  const {hashParam} = useHashParam('order')

  const isRunning = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      isRunning.current = false
    }
  }, []);


  const authenticate = async (pollQr: boolean): Promise<string | undefined> => {
    if (isRunning.current) {
      throw new Error("Already running")
    }

    console.log("Authenticating")
    setAutostartUrl(undefined)
    setQrCode(undefined)
    setHintCode(undefined)

    try {
      isRunning.current = true

      setStatus(BankIdStatus.Initializing)

      let orderRef = hashParam
      if (!orderRef) {
        const authResponse = await auth({})
        orderRef = authResponse.orderRef
        setAutostartUrl(createAutostartUrl(authResponse.autoStartToken, orderRef))
      }

      setStatus(BankIdStatus.Collecting)

      if (pollQr) {
        startPollQrCode(orderRef)
      }

      const collectResponse = await pollCollect(orderRef)

      if (collectResponse?.status === "complete") {
        setStatus(BankIdStatus.Success)
        return collectResponse.token
      } else if (collectResponse?.status === "failed") {
        setStatus(BankIdStatus.Failure)
      }
    } finally {
      isRunning.current = false
    }
  }

  const startPollQrCode = (orderRef: string) => {
    const QR_POLL_INTERVAL = 2000;
    let qrIntervalId: NodeJS.Timeout;
    console.log("Polling for QR code");

    setInterval(async () => {
      if (!isRunning.current) {
        clearInterval(qrIntervalId);
        return;
      }
      const qrCodeResponse = await qr(orderRef);
      setQrCode(qrCodeResponse);
    }, QR_POLL_INTERVAL);
  }

  const pollCollect = async (orderRef: string): Promise<CollectResponse | undefined> => {
    const COLLECT_POLL_INTERVAL = 2000;
    const MAX_COLLECT_RETRIES = 29;

    console.log("Collecting auth token");

    for (let i = 0; i < MAX_COLLECT_RETRIES; i++) {
      if (!isRunning.current) {
        //This means the component has been unmounted, we should stop polling.
        //Status and result will not matter since the component is gone.
        break
      }

      const response = await collect({orderRef});
      setHintCode(response.hintCode)

      if (response.status === 'complete' || response.status === 'failed') {
        return response
      }

      await new Promise(resolve => setTimeout(resolve, COLLECT_POLL_INTERVAL));
    }
  }

  const createAutostartUrl = (autostartToken: string, orderRef: string) => {
    if (isIosDevice) {
      const returnURL = createReturnUrl(window.location.href, orderRef);
      return "https://app.bankid.com/?autostarttoken=" + autostartToken + "&redirect=" + returnURL;
    }

    // Android, desktop
    return "bankid:///?autostarttoken=" + autostartToken + "&redirect=null";
  }

  const createReturnUrl = (url: string, orderRef: string) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.hash.substring(1));
    params.set('order', orderRef);
    urlObj.hash = params.toString();

    const returnUrl = urlObj.toString();

    if (isChromeOnIosDevice) {
      return "googlechromes://" + encodeURIComponent(returnUrl.replace("https://", "").replace("http://", ""));
    }
    if (isFirefoxOnIosDevice) {
      return "firefox://open-url?url=" + encodeURIComponent(returnUrl);
    }
    if (isEdgeOnIosDevice) {
      return "microsoft-edge-https://" + encodeURIComponent(returnUrl.replace("https://", "").replace("http://", ""));
    }

    return encodeURIComponent(returnUrl);
  }

  return {
    status,
    qrCode,
    autostartUrl,
    hintCode,
    authenticate: () => authenticate(false),
    authenticateQr: () => authenticate(true)
  };
};
