import {PortalRoleModel} from "../redux/types";
import {useAppSelector} from "../redux/hooks";
import {hasAnyRequiredPolicy} from "../util/role";

interface Props {
  children: any
  policy: keyof PortalRoleModel | (keyof PortalRoleModel)[]
}

export const RenderForPolicy = ({children, policy}: Props) => {
  const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
  const currentCustomerId = useAppSelector(state => state.auth.currentCustomerId)

  const policies = Array.isArray(policy) ? policy : [policy];

  if (!hasAnyRequiredPolicy(authenticatedUser, currentCustomerId, ...policies)) {
    return null
  }

  return children
}
