import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {createUseStyles} from 'react-jss'
import {Link} from 'react-router-dom'
import Card from "../components/Card"
import EditableField from '../components/EditableField'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import InfoCard from '../components/InfoCard'
import InfoIcon from '../components/InfoIcon'
import Switch from '../components/Switch'
import {Colors} from '../constants/colors'
import usePortalUserActions from '../hooks/usePortalUserActions'
import {useAppSelector} from '../redux/hooks'
import {DayOfWeek, EmployerLogoModel, CustomerPortalUser} from '../redux/types'
import {RenderForPolicy} from "../components/RenderForPolicy";
import useCustomerActions from '../hooks/useCustomerActions'

const CompanySettings = () => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'companySettings']);
  const {getUsers} = usePortalUserActions()
  const {setActivationNotificationDaysOfWeek, setInvoiceEmailAddress} = useCustomerActions()

  const customer = useAppSelector(state => state.customer.customer)
  const customerLoading = useAppSelector(state => state.customer.loading)
  const portalUsers = useAppSelector(state => state.portalUser.portalUsers)
  const portalUsersLoading = useAppSelector(state => state.portalUser.loading)

  useEffect(() => {
    if (portalUsers.length === 0) {
      getUsers()
    }
  }, [])

  const handleActivationNotificationDayChanged = (day: DayOfWeek) => {
    if (customer?.employer?.activationNotificationDaysOfWeek?.includes(day)) {
      const daysOfWeek = customer?.employer?.activationNotificationDaysOfWeek.filter(x => x !== day)
      setActivationNotificationDaysOfWeek(daysOfWeek)
    } else {
      setActivationNotificationDaysOfWeek([...customer?.employer?.activationNotificationDaysOfWeek ?? [], day])
    }
  }

  const handleInvoiceEmailAddressChanged = (email: string) => {
    setInvoiceEmailAddress(email)
  }

  const renderContacts = (predicate: (user: CustomerPortalUser) => boolean) => {
    const contacts = portalUsers?.filter(predicate) ?? []

    if (contacts.length === 0) {
      return (
        <div>
          <span>{t('notProvided', {ns: 'common'})} - </span>
          <RenderForPolicy policy={"allowManageUsers"}>
            <Link to={'/portalUsers'}>{t('assign', {ns: 'common'})}</Link>
          </RenderForPolicy>
        </div>)
    }

    return contacts.map((contact) => {
      return (
        <div key={`${contact.portalUserId}`}>
          {contact.givenName} {contact.familyName} ({contact.email})
        </div>
      )
    })
  }

  const renderDaySwitch = (day: DayOfWeek) => {
    return (
      <Switch
        id={day}
        key={day}
        checked={customer?.employer?.activationNotificationDaysOfWeek?.includes(day) ?? false}
        label={t(day.toString().toLowerCase(), {ns: 'common'})}
        onChange={() => handleActivationNotificationDayChanged(day)}
      />
    )
  }

  const renderLogos = (logos: EmployerLogoModel[] | undefined) => {
    if (!logos) return

    return logos?.map((logo, index) => {
      const imageBackground = logo.theme === 'DarkTheme' ? styles.darkLogoBackground : styles.lightLogoBackground

      return (
        <div className={imageBackground} key={index}>
          <img
            className={styles.logo}
            src={`data:${logo.contentType};base64,${logo.image}`}
            alt={logo.theme}
            title={logo.theme}/>
        </div>
      )
    })
  }

  return (
    <>
      <Card className={styles.card}>
        <h2>
          {t('title', {ns: 'companySettings'})}
        </h2>
      </Card>
      <div className={styles.infoCardContainer}>
        <InfoCard title={t('companyInfo', {ns: 'companySettings'})} className={styles.infoCard}>
          <div className={styles.row}>
            <div className={styles.label}>{t('companyRegistrationNumber', {ns: 'companySettings'})}</div>
            <div>{customer?.companyRegistrationNumber}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('legalName', {ns: 'companySettings'})}</div>
            <div>{customer?.legalName}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('displayName', {ns: 'companySettings'})}</div>
            <div>{customer?.name}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('abbreviation', {ns: 'companySettings'})}</div>
            <div>{customer?.employer?.abbreviation}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('logos', {ns: 'companySettings'})}</div>
            <div className={styles.logoContainer}>{renderLogos(customer?.employer?.logos)}</div>
          </div>
        </InfoCard>
        <InfoCard title={t('withdrawalLimitations', {ns: 'companySettings'})} className={styles.infoCard}>
          <div className={styles.row}>
            <div className={styles.label}>{t('general', {ns: 'companySettings'})}</div>
            <div>{t('percentageOfEarnedPayTemplate', {
              ns: 'companySettings',
              percentage: customer?.employer?.payoutSettings.maxPercentPerPayday
            })}</div>
            <div>{t('maxAmountPerPaydayTemplate', {
              ns: 'companySettings',
              amount: customer?.employer?.payoutSettings?.maxAmountPerPayday?.toLocaleString('sv-SE') + ' ' + customer?.employer?.payoutSettings?.currency?.toUpperCase()
            })}</div>
          </div>

          {customer?.employer?.payoutSettings.enableCustomShiftSettings &&
              <div className={styles.row}>
                  <div className={styles.label}>{t('customShifts', {ns: 'companySettings'})}</div>
                  <div>{t('percentageOfCustomEarnedPayTemplate', {
                    ns: 'companySettings',
                    percentage: customer?.employer?.payoutSettings.maxCustomPercentPerPayday
                  })}</div>
              </div>
          }

          <div className={styles.row} style={{flexGrow: 1}}></div>
        </InfoCard>
      </div>
      <InfoCard title={t('contactPersons', {ns: 'companySettings'})} className={styles.infoCard}>
        <div className={styles.infoCardContainer}>
          <div className={styles.row}>
            <div className={styles.label}>{t('finance', {ns: 'companySettings'})} <InfoIcon
              text={t('financeContactDescription', {ns: 'companySettings'})}/></div>
            <div>{renderContacts(x => x.isContactFinance)}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('payroll', {ns: 'companySettings'})} <InfoIcon
              text={t('payrollContactDescription', {ns: 'companySettings'})}/></div>
            <div>{renderContacts(x => x.isContactPayroll)}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('technical', {ns: 'companySettings'})} <InfoIcon
              text={t('technicalContactDescription', {ns: 'companySettings'})}/></div>
            <div>{renderContacts(x => x.isContactTechnical)}</div>
          </div>
        </div>
      </InfoCard>
      <InfoCard title={t('employeeActivationNotifications', {ns: 'companySettings'})} className={styles.infoCard}>
        <div className={styles.row}>
          <div className={styles.label}>{t('recipients', {ns: 'companySettings'})} <InfoIcon
            text={t('activationAdminDescription', {ns: 'companySettings'})}/></div>
          <div>{renderContacts(x => x.portalRoles.some(role => role.receiveActivationNotification))}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t('notificationDays', {ns: 'companySettings'})} <InfoIcon
            text={t('notificationDaysDescription', {ns: 'companySettings'})}/></div>
          <div className={styles.daySwitchContainer}>
            {renderDaySwitch('Monday')}
            {renderDaySwitch('Tuesday')}
            {renderDaySwitch('Wednesday')}
            {renderDaySwitch('Thursday')}
            {renderDaySwitch('Friday')}
            {renderDaySwitch('Saturday')}
            {renderDaySwitch('Sunday')}
          </div>
        </div>
      </InfoCard>
      <div className={styles.infoCardContainer}>
        {customer?.employer?.adminFlowType === 'basisImport' &&
            <InfoCard title={t('paymentNotifications', {ns: 'companySettings'})} className={styles.infoCard}>
                <div className={styles.row}>
                    <div className={styles.label}>{t('invoiceEmailAddress', {ns: 'companySettings'})} <InfoIcon
                        text={t('invoiceEmailAddressDescription', {ns: 'companySettings'})}/></div>
                    <div>
                        <EditableField
                            onEdited={handleInvoiceEmailAddressChanged}
                            value={customer?.invoiceEmailAddress}
                            validationRegex={/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}
                            placeholder={t('notProvided', {ns: 'common'})}
                        />
                    </div>
                </div>
            </InfoCard>
        }
      </div>
      <FullScreenLoadingIndicator visible={customerLoading || portalUsersLoading}/>
    </>
  )
}

export default CompanySettings

const useStyles = createUseStyles({
  card: {
    marginBottom: 20
  },
  infoCard: {
    flex: 1
  },
  row: {
    flex: 1,
    marginBottom: 10,
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
    textTransform: 'uppercase',
    fontSize: 13,
    color: Colors.gray3
  },
  infoCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    columnGap: 20
  },
  daySwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 40
  },
  listItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listCellContainer: {
    width: 170
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10
  },
  lightLogoBackground: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray0,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  darkLogoBackground: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.gray4,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: Colors.gray0,
    borderRadius: '16px',
    width: '32px',
    height: '32px',
  },
  logo: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  }
})
