import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {createUseStyles} from 'react-jss'
import {Breakpoints} from '../../constants/breakpoints'
import {Colors} from "../../constants/colors"
import {Fonts} from '../../constants/fonts'
import {
  EmployerInitiatedPayoutJobListItem,
  EmployerInitiatedPayoutJobState,
  Payroll,
  PayrollStatus
} from "../../redux/types"
import {formatDateString, formatDateTimeString} from '../../util/date'
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import {PayrollItem} from "./usePayrollItems";
import EmployerInitiatedPayoutJobModal from "./EmployerInitiatedPayoutJobModal";

interface Props {
  payrollItems: PayrollItem[]
  pageIndex: number
  pageSize: number
  onPayRunInfoClicked: (paydayId: string) => void
  onPageChange: (pageIndex: number) => void
}

interface PayrollRowProps {
  payroll: Payroll
  index: number
  onClick: (paydayId: string) => void
}

interface EmployerInitiatedPayoutJobRowProps {
  employerInitiatedJob: EmployerInitiatedPayoutJobListItem
  index: number,
}

const PayrollRow = ({payroll, index, onClick}: PayrollRowProps) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'payrolls']);

  const getStatusColor = (status: PayrollStatus) => {
    switch (status) {
      case 'running':
        return Colors.yen_300
      case 'completed':
        return Colors.euro_300
    }
  }

  const getStatusTextColor = (status: PayrollStatus) => {
    switch (status) {
      case 'running':
        return Colors.gray0
      case 'completed':
        return Colors.dollar_900
    }
  }

  return (
    <ListItemCard key={`payrollCard${index}`} className={styles.listItemContainer} index={index}>
      <div className={styles.defaultCellContainer}>
        {payroll.paydayDate}
      </div>
      <div className={styles.defaultCellContainer}>
        {payroll.offCycle ? t('offCycle', {ns: 'payrolls'}) : t('standard', {ns: 'payrolls'})}
      </div>
      <div className={styles.badgeContainer}>
        <div className={styles.badge} style={{backgroundColor: getStatusColor(payroll.status)}}>
          <span className={styles.badgeText}
                style={{color: getStatusTextColor(payroll.status)}}>{t(`status_${payroll.status}`, {ns: 'payrolls'})}</span>
        </div>
      </div>
      <div className={styles.defaultCellContainer}>
        {payroll.remainingPaysToDeliver}
      </div>
      <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
        {formatDateTimeString(payroll.startedDate)}
      </div>
      <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
        {formatDateTimeString(payroll.completedDate)}
      </div>
      <div className={styles.buttonCell}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{cursor: 'pointer'}}
          onClick={() => onClick(payroll.paydayId)}
          title={t('displayPayRuns', {ns: 'payrolls'})}
        />
      </div>
    </ListItemCard>
  )
}

const EmployerInitiatedPayoutJobRow = ({employerInitiatedJob, index}: EmployerInitiatedPayoutJobRowProps) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'employerInitiatedPayout']);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)

  const getStatusColor = (state: EmployerInitiatedPayoutJobState) => {
    switch (state) {
      case 'scheduled':
        return Colors.dollar_300
      case 'pending':
        return Colors.yen_300
      case 'completed':
        return Colors.euro_300
      case 'cancelled':
        return Colors.gray2
    }
  }

  const getStatusTextColor = (state: EmployerInitiatedPayoutJobState) => {
    switch (state) {
      case 'scheduled':
        return Colors.dollar_900
      case 'pending':
        return Colors.yen_900
      case 'completed':
        return Colors.euro_900
      case 'cancelled':
        return Colors.gray4
    }
  }

  return (
    <ListItemCard key={`payrollCard${index}`} className={styles.listItemContainer} index={index}>
      <div className={styles.defaultCellContainer}>
        {formatDateString(employerInitiatedJob.scheduledDate)}
      </div>
      <div className={styles.defaultCellContainer}>
        {t('employerInitiatedPayout', {ns: 'employerInitiatedPayout'})}
      </div>
      <div className={styles.badgeContainer}>
        <div className={styles.badge} style={{backgroundColor: getStatusColor(employerInitiatedJob.state)}}>
              <span className={styles.badgeText} style={{color: getStatusTextColor(employerInitiatedJob.state)}}>
                {t(`job_state_${employerInitiatedJob.state}`, {ns: 'employerInitiatedPayout'})}
              </span>
        </div>
      </div>
      <div className={styles.defaultCellContainer}/>
      <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
        {formatDateTimeString(employerInitiatedJob.scheduledDate)}
      </div>
      <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
        {formatDateTimeString(employerInitiatedJob.completedDate ?? undefined)}
      </div>
      <div className={styles.buttonCell}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{cursor: 'pointer'}}
          onClick={() => setDetailsModalVisible(true)}
          title={t('showDetails', {ns: 'employerInitiatedPayout'})}
        />
      </div>

      <EmployerInitiatedPayoutJobModal
        visible={detailsModalVisible}
        jobId={employerInitiatedJob.id}
        onCloseClick={() => setDetailsModalVisible(false)}
      />
    </ListItemCard>
  )
}

const PayrollItemList = ({
                           payrollItems,
                           pageIndex,
                           pageSize,
                           onPayRunInfoClicked,
                           onPageChange
                         }: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'payrolls']);

  const itemsOnCurrentPage = useMemo(() => {
    const firstPageIndex = (pageIndex - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return payrollItems.slice(firstPageIndex, lastPageIndex);
  }, [payrollItems, pageIndex, pageSize])

  const renderHeader = () => {
    return (
      <ListItemCard className={styles.listItemContainer}>
        <div className={styles.defaultCellContainer}>
          <strong>{t('date', {ns: 'common'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('type', {ns: 'common'})}</strong>
        </div>
        <div className={styles.badgeContainer}>
          <strong>{t('status', {ns: 'common'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('undeliveredPays', {ns: 'payrolls'})}</strong>
        </div>
        <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
          <strong>{t('started', {ns: 'common'})}</strong>
        </div>
        <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
          <strong>{t('completed', {ns: 'common'})}</strong>
        </div>
        <div className={styles.buttonCell}>
        </div>
      </ListItemCard>
    )
  }

  const renderListItems = () => {
    return itemsOnCurrentPage?.map((payrollPageItem, index) => {
      if (payrollPageItem.type === 'payroll') {
        return (
          <PayrollRow
            key={`payrollRow${index}`}
            payroll={payrollPageItem.payroll}
            index={index}
            onClick={onPayRunInfoClicked}
          />
        )
      }

      if (payrollPageItem.type === 'employerInitiatedPayout') {
        return (
          <EmployerInitiatedPayoutJobRow
            employerInitiatedJob={payrollPageItem.employerInitiatedPayout}
            index={index}
          />
        )
      }
    })
  }

  return (
    <>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={payrollItems.length}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </>
  )
}

export default PayrollItemList

const useStyles = createUseStyles({
  listItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  defaultCellContainer: {
    display: 'flex',
    flex: 1,
  },
  smallHidden: {
    display: 'none',
    [`@media (min-width: ${Breakpoints.tablet}px)`]: {
      display: 'flex'
    },
  },
  buttonCell: {
    display: 'flex',
    width: 30
  },
  badgeContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start'
  },
  badge: {
    height: '20px',
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 20,
    backgroundColor: Colors.euro_800,
    margin: 2
  },
  badgeText: {
    display: 'block',
    fontSize: 14,
    color: Colors.gray4,
    fontFamily: Fonts.regular,
    lineHeight: '21px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
