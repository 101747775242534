import { useEffect, useState } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import { useAppDispatch } from '../redux/hooks'
import { EmployeeReadyForActivation } from "../redux/types"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import useEmployeeActivationActions from "../hooks/useEmployeeActivationActions"
import ConfirmModal from "../components/ConfirmModal"
import ActivationList from "../components/Activation/ActivationList"
import { Colors } from "../constants/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import Button from "../components/Button"
import { pageIndexChanged } from "../redux/slices/employeeActivationSlice"

const Activations = () => {
    const styles = useStyles()
    const { t } = useTranslation('activations');
    const dispatch = useAppDispatch()

    const [selection, setSelection] = useState<EmployeeReadyForActivation[]>([])

    const [confirmActivationModalVisible, setConfirmActivationModalVisible] = useState<boolean>(false)
    const [activationCandidates, setActivationCandidates] = useState<EmployeeReadyForActivation[]>([]) 

    const { getPendingEmployees, activateEmployees, setBankAccountActivationPendingForEmployees } = useEmployeeActivationActions()
    const loading = useAppSelector(state => state.employeeActivation.loading)
    const pendingEmployees = useAppSelector(state => state.employeeActivation.pendingEmployees)
    const pageIndex = useAppSelector(state => state.employeeActivation.pageIndex)
    const pageSize = useAppSelector(state => state.employeeActivation.pageSize)

    const customer = useAppSelector(state => state.customer.customer)

    useEffect(() => {
        if (pendingEmployees.length === 0) {
            getPendingEmployees()
        }
    }, [])

    const onActivateClick = (employee: EmployeeReadyForActivation) => {
        setActivationCandidates([employee])
        setConfirmActivationModalVisible(true)      
    }

    const onActivateSelectedClick = () => {
        setActivationCandidates([...selection])
        setConfirmActivationModalVisible(true)    
    }

    const handleActivationConfirmed = () => {
        if (customer?.employer?.employeeActivationType === 'manual') {
            activateEmployees(activationCandidates)
        } else {
            setBankAccountActivationPendingForEmployees(activationCandidates)
        }

        setActivationCandidates([])
        setConfirmActivationModalVisible(false)   
        setSelection([])  
    }

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const onSelectionChange = (selection: EmployeeReadyForActivation[]) => {
        setSelection([...selection])
    }

    const renderActivationGuide = (bankAccountActivation: boolean) => {
        return (
                <div>
                    <ol>
                        <li className={styles.activationGuideItemContainer}>
                            {t('activationGuideStep1Intro')}
                            <div className={styles.activationGuideValueContainer}>
                                {activationCandidates.map((candidate, index) => {
                                    return <p key={`candidate_${index}`}><strong>{candidate.givenName} {candidate.familyName} ({candidate.identificationNumber})</strong></p> 
                                })}
                            </div>
                            {t('activationGuideStep1Outro')}
                        </li>
                        <li className={styles.activationGuideItemContainer}>
                            {t('activationGuideStep2Intro')}
                            <div className={styles.activationGuideValueContainer} onClick={() => navigator.clipboard.writeText(customer?.employer?.bankAccount ?? '')} title={t('copyToClipboard')}>
                                <strong>{customer?.employer?.bankAccount}</strong>
                                <FontAwesomeIcon 
                                    style={{marginLeft: 10}}
                                    icon={faCopy}
                                    color={Colors.gray1}
                                />
                            </div>
                            {t('activationGuideStep2Outro')}
                        </li>
                        <li className={styles.activationGuideItemContainer}>
                            {t('activationGuideStep3')}
                        </li>
                        <li className={styles.activationGuideItemContainer}>
                            {!bankAccountActivation && <span>{t('activationGuideStep4Manual')}</span>}
                            {bankAccountActivation && <span>{t('activationGuideStep4BankAccount')}</span>}
                        </li>
                    </ol>

                    {!bankAccountActivation && 
                    <div className={styles.activationGuideNoteContainer}>
                        <strong>{t('activationGuideNote')}</strong>
                    </div>
                    }
                </div>
        )    
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
            <div className={styles.info}>
                {t('subtitle')}
            </div>
        </Card>
         <Card>
            <div className={styles.buttonContainer}>
                <Button 
                    disabled={selection.length === 0}
                    onClick={onActivateSelectedClick} 
                    title={t('activateSelected')} 
                    className={styles.button}  
                />
            </div>
            {pendingEmployees && 
            <ActivationList 
                employees={pendingEmployees}
                pageIndex={pageIndex}
                pageSize={pageSize} 
                onActivateClick={onActivateClick} 
                onPageChange={onPageChange} 
                onSelectionChanged={onSelectionChange}
            />}
           <ConfirmModal 
                title={t('activateEmployee')}     
                visible={confirmActivationModalVisible}
                okButtonTitle={t('confirmBankAccountChange')} 
                onConfirmClick={handleActivationConfirmed}
                onCancelClick={() => setConfirmActivationModalVisible(false)}
            >
                {renderActivationGuide(customer?.employer?.employeeActivationType !== 'manual')}
            </ConfirmModal>
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
} 

export default Activations

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    info: {
        marginTop: 20,
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 10
    },
    button: {
        alignSelf: 'flex-end'
    },
    activationGuideValueContainer: {
        marginTop: 10,
        marginBottom: 10
    },
    activationGuideItemContainer: {
        marginBottom: 20
    },
    activationGuideNoteContainer: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        marginTop: 40
    }
})