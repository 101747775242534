import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import { CurrentUser, Language } from '../redux/types'
import InputField from './InputField'
import { useTranslation } from 'react-i18next'
import useCurrentUserActions from '../hooks/useCurrentUserActions'
import { useAppSelector } from '../redux/hooks'

interface Props {
    onCloseClick?: () => void
    visible: boolean
}

const MyProfileModal = ({
    onCloseClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const [user, setUser] = useState<CurrentUser>()

    const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
    const { updateUser } = useCurrentUserActions()

    useEffect(() => {
        if (visible && authenticatedUser) {
            setUser({...authenticatedUser})
        }
    }, [visible])

    const handleOnSave = () => {
        if (user) {
            updateUser({
                givenName: user.givenName,
                familyName: user.familyName,
                phoneNumber: user.phoneNumber,
                language: authenticatedUser?.language as Language
            })
        }
        handleOnClose()
    }

    const handleOnClose = () => {
        onCloseClick && onCloseClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        if (user)
            setUser({...user, [propName]: value})
    }

    const isInformationMissing = () => {
        if ((authenticatedUser?.givenName ?? '').length === 0 ||
            (authenticatedUser?.familyName ?? '').length === 0 ||
            (authenticatedUser?.phoneNumber ?? '').length === 0) {
            return true
        }
        return false
    }

    return (
        <Modal
            title={t('myProfile')}
            visible={visible}
            okButtonTitle={t('save', {ns: 'common'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            {isInformationMissing() &&
            <div>
                <h5 style={{marginBottom: 20}}>{t('welcome', {ns: 'common'})}</h5>
                <p>{t('provideMoreProfileInfo', {ns: 'common'})}</p>
            </div>
            }
            <div className={styles.row}>
                <InputField
                    disabled={true}
                    id='identificationNumber'
                    value={user?.identificationNumber ?? ''}
                    label={t('identificationNumber', {ns: 'common'})}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <InputField
                    disabled={true}
                    id='email'
                    value={user?.email ?? ''}
                    label={t('email', {ns: 'common'})}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <InputField
                    id='givenName'
                    value={user?.givenName ?? ''}
                    onChange={value => handleOnChange('givenName', value)}
                    label={t('givenName', {ns: 'common'})}
                    mandatory={true}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <InputField
                    id='familyName'
                    value={user?.familyName ?? ''}
                    onChange={value => handleOnChange('familyName', value)}
                    label={t('familyName', {ns: 'common'})}
                    mandatory={true}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <InputField
                    id='phoneNumber'
                    value={user?.phoneNumber ?? ''}
                    onChange={value => handleOnChange('phoneNumber', value)}
                    label={t('phoneNumber', {ns: 'common'})}
                    mandatory={true}
                    placeholder=''
                />
            </div>
        </Modal>
    )
}

export default MyProfileModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    }
})
