import { useEffect, useState } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import { useAppDispatch } from '../redux/hooks'
import {
    BulkOperationResult,
    EditShiftModel,
    Shift,
    ShiftDataParserType,
    ShiftListFilter,
    ShiftStatus
} from "../redux/types"
import Button from '../components/Button'
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import useShiftActions from "../hooks/useShiftActions"
import ConfirmModal from "../components/ConfirmModal"
import { pageIndexChanged, filterChanged, errorHandled } from "../redux/slices/shiftSlice"
import ShiftList from "../components/ShiftList/ShiftList"
import FilterComponent from "../components/ShiftList/ShiftListFilter"
import EditShiftModal from "../components/EditShiftModal"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"
import { useTranslation } from "react-i18next"
import UploadFileModal from "../components/UploadFileModal"
import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/SuccessModal"

const Shifts = () => {
    const styles = useStyles()
    const { t } = useTranslation(['shifts', 'common']);
    const dispatch = useAppDispatch()

    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState<boolean>(false)
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [uploadFileModalVisible, setUploadFileModalVisible] = useState<boolean>(false)
    const [uploadFileDataType, setUploadFileDataType] = useState<ShiftDataParserType>()
    const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false)
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)

    const [bulkOperationResult, setBulkOperationResult] = useState<BulkOperationResult>()

    const [editedShift, setEditedShift] = useState<EditShiftModel>()
    const [deleteCandidate, setDeleteCandidate] = useState<Shift>()

    const { getShifts, createShift, updateShift, deleteShift, bulkUploadShifts } = useShiftActions()
    const loading = useAppSelector(state => state.shift.loading)
    const shiftsOnPage = useAppSelector(state => state.shift.shiftsOnPage)
    const totalShiftCount = useAppSelector(state => state.shift.totalShiftCount)
    const pageIndex = useAppSelector(state => state.shift.pageIndex)
    const pageSize = useAppSelector(state => state.shift.pageSize)
    const filter = useAppSelector(state => state.shift.filter)
    const error = useAppSelector(state => state.shift.error)

    const customer = useAppSelector(state => state.customer.customer)

    useEffect(() => {
        if (shiftsOnPage.length === 0)
            getShifts(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, filter?.status ?? undefined, pageIndex, pageSize)
    }, [])

    useDidUpdateEffect(() => {
        getShifts(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, filter?.status ?? undefined, pageIndex, pageSize)
    }, [filter, pageIndex])

    useDidUpdateEffect(() => {
        if (error) {
            setErrorModalVisible(true)
        }
    }, [error])

    const handleError = () => {
        dispatch(errorHandled())
        setErrorModalVisible(false)
    }

    const onAddClick = () => {
        setAddModalVisible(true)
    }

    const handleAdded = (shift: EditShiftModel) => {
        createShift(shift, () => {
            getShifts(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, filter?.status ?? undefined, pageIndex, pageSize)
        })
        setAddModalVisible(false)
    }

    const onEditClick = (shift: Shift) => {
        setEditedShift({
            id: shift.id,
            externalId: shift.externalId,
            externalEmployeeId: shift.employee.externalId,
            employeeId: shift.employee.id,
            employeeName: shift.employee.name,
            employeeIdentificationNumber: shift.employee.identificationNumber,
            date: shift.date,
            type: shift.type,
            status: shift.status,
            hours: shift.hours,
            grossWages: shift.grossWages.amount,
            startTime: shift.startTime,
            endTime: shift.endTime,
            description: shift.description,
            location: shift.location
        })
        setEditModalVisible(true)
    }

    const handleEdited = (editModel: EditShiftModel) => {
        updateShift(editModel)
        setEditedShift(undefined)
        setEditModalVisible(false)
    }

    const handleEditCancelled = () => {
        setEditedShift(undefined)
        setEditModalVisible(false)
    }

    const onDeleteClick = (shift: Shift) => {
        setDeleteCandidate(shift)
        setConfirmDeleteModalVisible(true)
    }

    const handleDeleteConfirmed = () => {
        if (deleteCandidate) {
            deleteShift(deleteCandidate)
            setDeleteCandidate(undefined)
        }
        setConfirmDeleteModalVisible(false)
    }

    const handleFileUploaded = (file: File) => {
        bulkUploadShifts(file, uploadFileDataType as ShiftDataParserType, (result: BulkOperationResult) => {
            setBulkOperationResult(result)
            getShifts(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, filter?.status ?? undefined, pageIndex, pageSize)
            setSuccessModalVisible(true)
        })
        setUploadFileModalVisible(false)
    }

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const handleFilterChange = (filter: ShiftListFilter) => {
        dispatch(filterChanged(filter))
    }

    const handleUploadFileButtonClick = (dataType: ShiftDataParserType) => {
        setUploadFileDataType(dataType)
        setUploadFileModalVisible(true)
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
            {t('title', {ns: 'shifts'})}
            </h2>
        </Card>
        <Card>
            <div className={styles.buttonContainer}>
                <Button onClick={onAddClick} title={t('addShift', {ns: 'shifts'})} className={styles.button}  />
                <Button onClick={() => handleUploadFileButtonClick("csv")} title={t('uploadCsvFile', {ns: 'common'})} className={styles.button}  />

                {customer?.employer && customer.employer.shiftDataParserType !== "csv" &&
                    <Button onClick={() => handleUploadFileButtonClick(customer!.employer!.shiftDataParserType)} title={t('uploadCustomFileTemplate', {ns: 'common', type: customer!.employer!.shiftDataParserType.toString().toUpperCase()})} className={styles.button}  />
                }
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    initialFilterValue={filter ?? undefined}
                    onFilterChange={handleFilterChange}
                />
            </div>
            {shiftsOnPage &&
            <ShiftList
                shiftsOnPage={shiftsOnPage}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalShiftCount={totalShiftCount}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onPageChange={onPageChange}
            />}
            <EditShiftModal
                key='addModal'
                visible={addModalVisible}
                onCancelClick={() => setAddModalVisible(false)}
                onSaveClick={handleAdded}
            />
            <EditShiftModal
                key='editModal'
                initialValue={editedShift}
                visible={editModalVisible}
                onCancelClick={handleEditCancelled}
                onSaveClick={handleEdited}
            />
            <UploadFileModal
                visible={uploadFileModalVisible}
                multiFileUpload={false}
                title={t('uploadFile', {ns: 'shifts'})}
                infoText={t('uploadFileDescription', {ns: 'shifts'})}
                onCancelClick={() => setUploadFileModalVisible(false)}
                onUpload={files => handleFileUploaded(files[0])}
            />
            <ConfirmModal
                visible={confirmDeleteModalVisible}
                confirmText={t(`confirmDelete_${deleteCandidate?.status ?? ShiftStatus.Completed}`, {ns: 'shifts'})}
                onConfirmClick={handleDeleteConfirmed}
                onCancelClick={() => setConfirmDeleteModalVisible(false)}
            />
            <ErrorModal
                visible={errorModalVisible}
                errorText={error?.toString()}
                onCloseClick={handleError}
            />
            <SuccessModal
                visible={successModalVisible}
                bulkOperationResult={bulkOperationResult}
                onCloseClick={() => setSuccessModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
}

export default Shifts

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20,
        columnGap: 10
    },
    filterContainer: {
        marginBottom: 20,
    },
    button: {
        alignSelf: 'flex-end'
    }
})
