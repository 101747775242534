import { useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import Card from "../components/Card"
import FilesByMonth from "../components/FilesByMonth"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import InfoIcon from "../components/InfoIcon"
import { Colors } from "../constants/colors"
import { useAppSelector } from "../redux/hooks"

const PaymentNotifications = () => {
    const styles = useStyles()
    const { t } = useTranslation(['paymentNotifications', 'common']);

    const customer = useAppSelector(state => state.customer.customer)
    const filesLoading = useAppSelector(state => state.files.loading)

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title', {ns: 'paymentNotifications'})}
            </h2>
        </Card>
        <Card className={styles.card}>
            <div className={styles.row}>
                <div className={styles.label}>
                    {t('invoiceEmailAddress', {ns: 'companySettings'})} <InfoIcon text={t('invoiceEmailAddressDescription', {ns: 'companySettings'})} />
                </div>
                <div>
                    <span>{(customer?.invoiceEmailAddress == null || customer?.invoiceEmailAddress.length < 1)
                        ? t('notProvided', {ns: 'common'})
                        : customer.invoiceEmailAddress}
                    </span>
                    <span> - </span>
                    <Link to={'/companySettings'}>
                        {(customer?.invoiceEmailAddress == null || customer?.invoiceEmailAddress.length < 1)
                            ? t('assign', {ns: 'common'})
                            : t('edit', {ns: 'common'})
                        }
                    </Link>
                </div>
            </div>
        </Card>
        <Card>
            <FilesByMonth
                showBankAccountImportFiles={false}
                showCreditReportFiles={false}
                showDeductionBasisForPaydayFiles={false}
                showEmployerPayDeductionPaymentNotificationFiles={true}
                showPayoutBasisForCalendarMonthFiles={false}
                showAgiAdjustmentSieFiles={false}
                showAgiAdjustmentReportFiles={false}
                showForaAdjustmentReportFiles={false}
                showEmployeeForeclosureReportFiles={false}
                showRemainingDebtReportFiles={false}
                showGrossAdvanceReportFiles={false}
            />
        </Card>
        <FullScreenLoadingIndicator visible={filesLoading} />
        </>
    )
}

export default PaymentNotifications

const useStyles = createUseStyles({
    container: {
        marginBottom: 40
    },
    card: {
        marginBottom: 20
    },
    row: {
        flex: 1,
        marginBottom: 10,
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 10,
        textTransform: 'uppercase',
        fontSize: 13,
        color: Colors.gray3
    },
})
