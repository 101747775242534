import { apiConfig } from "../apiConfig"
import { useAppDispatch } from "../redux/hooks"
import useFetchClient from "./useFetchClient"
import { loading, authenticatedUserUpdated, authenticatedUserUpdatedFailure } from "../redux/slices/authUserSlice";
import { CurrentUser, CurrentUserEditModel } from "../redux/types";
import { useTranslation } from "react-i18next";

const useCurrentUserActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation();

    const updateUser = (model: CurrentUserEditModel) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.currentUser, model)
            .then(response => {
                const user = response.data as CurrentUser
                dispatch(authenticatedUserUpdated(user))
                i18n.changeLanguage(user.language)
            })
            .catch(error => {
                console.log('updateUser error:', error)
                const errorText = error instanceof Error ? error.message : error.toString()
                dispatch(authenticatedUserUpdatedFailure(errorText))
            })
    }

    return {
        updateUser,
    }
}

export default useCurrentUserActions
