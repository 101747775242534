import React from 'react';
import { createUseStyles } from 'react-jss';
import { Colors } from '../constants/colors';

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

const TextButton = (props: Props) => {
  const styles = useStyles();

  return (
    <button className={`${styles.textButton} ${props.className}`} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default TextButton;

const useStyles = createUseStyles({
  textButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: Colors.peso_700,
    '&:hover': {
      color: Colors.peso_800,
    },
    '&:focus': {
      outline: 'none',
    },
  },
});
