import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../redux/hooks'
import Select from './Select'
import useAuthUserActions from '../hooks/auth/useAuthUserActions'

interface Props {
    onCloseClick?: () => void
    visible: boolean
}

const CustomerSelectorModal = ({
    onCloseClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>()

    const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
    const currentCustomerId = useAppSelector(state => state.auth.currentCustomerId)

    const { switchCustomer } = useAuthUserActions()

    useEffect(() => {
        if (visible && currentCustomerId) {
            setSelectedCustomerId(currentCustomerId)
        }
    }, [visible])

    const handleOnSelect = () => {
        if (selectedCustomerId && selectedCustomerId !== currentCustomerId)
            switchCustomer(selectedCustomerId)

        handleOnClose()
    }

    const handleOnClose = () => {
        onCloseClick && onCloseClick()
    }

    const getOptions = () => {
        if (!authenticatedUser)
            return []

        return authenticatedUser.customers.map(x => {
            return { name: x.customerName, value: x.customerId }
        })
    }

    return (
        <Modal
            title={t('selectCustomer')}
            visible={visible}
            okButtonTitle={t('select')}
            cancelButtonTitle={t('cancel')}
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSelect()}
            onCancelClick={() => handleOnClose()}
        >
            <div className={styles.row}>
                <Select
                    id='customer'
                    key='customer'
                    label={t('company')}
                    options={getOptions()}
                    selectedValue={currentCustomerId ?? undefined}
                    onSelect={(value) => setSelectedCustomerId(value)}
                />
            </div>
        </Modal>
    )
}

export default CustomerSelectorModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    }
})
