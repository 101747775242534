import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import Button from "../../components/Button"
import Card from "../../components/Card"
import ConfirmModal from "../../components/ConfirmModal"
import FullScreenLoadingIndicator from "../../components/FullScreenLoadingIndicator"
import InfoCard from "../../components/InfoCard"
import { Colors } from "../../constants/colors"
import useFortnoxIntegrationActions from "../../hooks/integrations/useFortnoxIntegrationActions"
import { useAppSelector } from "../../redux/hooks"
import usePlandayIntegrationActions from "../../hooks/integrations/usePlandayIntegrationActions"
import UploadFileModal from "../../components/UploadFileModal"
import useTengellaIntegrationActions from "../../hooks/integrations/useTengellaIntegrationActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { relative } from "path"

const Integrations = () => {
    const styles = useStyles()
    const { t } = useTranslation('integrations');

    const { getStatus: getFortnoxStatus, getAuthenticationUrl: getFortnoxAuthenticationUrl } = useFortnoxIntegrationActions()
    const { getStatus: getPlandayStatus, getAuthenticationUrl: getPlandayAuthenticationUrl } = usePlandayIntegrationActions()
    const { uploadFile: uploadTengellaFile } = useTengellaIntegrationActions()

    const customer = useAppSelector(state => state.customer.customer)

    const fortnoxLoading = useAppSelector(state => state.fortnoxIntegration.loading)
    const fortnoxIntegrationActivated = useAppSelector(state => state.fortnoxIntegration.activated)
    const fortnoxAuthenticationUrl = useAppSelector(state => state.fortnoxIntegration.authenticationUrl)

    const plandayLoading = useAppSelector(state => state.plandayIntegration.loading)
    const plandayIntegrationActivated = useAppSelector(state => state.plandayIntegration.activated)
    const plandayAuthenticationUrl = useAppSelector(state => state.plandayIntegration.authenticationUrl)

    const tengellaLoading = useAppSelector(state => state.tengellaIntegration.loading)
    const tengellaFileUploaded = useAppSelector(state => state.tengellaIntegration.fileUploaded)
    const tengellaFileUploadError = useAppSelector(state => state.tengellaIntegration.error)

    const [integrationActivationCandidate, setIntegrationActivationCandidate] = useState<string>()
    const [activationConfirmationModalVisible, setActivationConfirmationModalVisible] = useState<boolean>(false)
    const [integrationFileUploadCandidate, setIntegrationFileUploadCandidate] = useState<string>()
    const [fileUploadModalVisible, setFileUploadModalVisible] = useState<boolean>(false)

    useEffect(() => { 
        if (customer?.portalIntegrations.includes('Fortnox')) {
            if (fortnoxIntegrationActivated === null)
                getFortnoxStatus()

            if (fortnoxAuthenticationUrl === null || fortnoxAuthenticationUrl.length === 0)
                getFortnoxAuthenticationUrl()
        }

        if (customer?.portalIntegrations.includes('Planday')) {
            if (plandayIntegrationActivated === null)
                getPlandayStatus()

            if (plandayAuthenticationUrl === null || plandayAuthenticationUrl.length === 0)
                getPlandayAuthenticationUrl()
        }
    }, [customer])

    const activateIntegrationButtonClicked = (integrationName: string) => {
        setIntegrationActivationCandidate(integrationName)
        setActivationConfirmationModalVisible(true)
    }

    const activateIntegration = () => {
        let activationUrl = ''

        switch (integrationActivationCandidate) {
            case 'Fortnox':
                activationUrl = fortnoxAuthenticationUrl!
                break;
            case 'Planday':
                activationUrl = plandayAuthenticationUrl!
                break;
        }

        setActivationConfirmationModalVisible(false)
        window.open(activationUrl, '_blank')
    }

    const onFileUploadButtonClicked = (integrationName: string) => {
        setIntegrationFileUploadCandidate(integrationName)
        setFileUploadModalVisible(true)
    }

    const onFileUploaded = (file: File) => {
        setFileUploadModalVisible(false)

        switch (integrationFileUploadCandidate) {
            case 'Tengella':
                uploadTengellaFile(file)
                break;
        }
    }

    const renderActivatableIntegrationCard = (title: string, activated: boolean, authUrlExists: boolean, onActivateButtonClick: () => void) => {
        return (
            <InfoCard title={title} className={styles.infoCard}>
                <div className={styles.row}>
                    <div className={styles.label}>{t('status')}</div>
                    <div>{activated ? t('activated') : t('notActivated')}</div>
                </div>
                <div className={styles.row}>
                    {authUrlExists &&
                    <Button 
                        className={styles.button} 
                        title={activated ? t('reactivateIntegration') : t('activateIntegration')} 
                        onClick={onActivateButtonClick} 
                        buttonStyle={{width: '100%'}}
                    /> 
                    }
                </div>
            </InfoCard> 
        )
    }

    const renderFileUploadIntegrationCard = (title: string, integrationName: string) => {
        let icon = tengellaFileUploadError != null ? faExclamationTriangle : faCheck
        let color = tengellaFileUploadError != null ? Colors.rubel_700: Colors.dollar_900
        
        return (
            <InfoCard title={title} className={styles.infoCard}>
                { (tengellaFileUploaded || tengellaFileUploadError != null) && 
                <div className={styles.centeredRow}>
                    <FontAwesomeIcon
                        icon={icon}
                        size='2x'
                        color={color}
                    />
                </div>
                }
                <div className={styles.row}>
                    <Button 
                        className={styles.button} 
                        title={t('uploadFile')} 
                        onClick={() => onFileUploadButtonClicked(integrationName)} 
                        buttonStyle={{width: '100%'}}
                    /> 
                </div>
            </InfoCard> 
        )
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
        </Card>
        <div className={styles.integrationContainer}>
            { customer?.portalIntegrations.includes('Fortnox') && renderActivatableIntegrationCard('Fortnox', fortnoxIntegrationActivated ?? false, fortnoxAuthenticationUrl != null, () => activateIntegrationButtonClicked('Fortnox')) }
            { customer?.portalIntegrations.includes('Planday') && renderActivatableIntegrationCard('Planday', plandayIntegrationActivated ?? false, plandayAuthenticationUrl != null, () => activateIntegrationButtonClicked('Planday')) }
            { customer?.portalIntegrations.includes('Tengella') && renderFileUploadIntegrationCard('Tengella', 'Tengella') }
        </div>
        <ConfirmModal     
            visible={activationConfirmationModalVisible}
            confirmText={t('confirmActivation')}
            onConfirmClick={activateIntegration}
            onCancelClick={() => setActivationConfirmationModalVisible(false)}
        />
        <UploadFileModal
            visible={fileUploadModalVisible}
            multiFileUpload={false}
            title={t('uploadFile')}
            onCancelClick={() => setFileUploadModalVisible(false)}
            onUpload={(files) => {
                setFileUploadModalVisible(false)
                onFileUploaded(files[0])
            }}
        />
        <FullScreenLoadingIndicator visible={fortnoxLoading || plandayLoading || tengellaLoading} />
        </>
    )
}

export default Integrations

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    info: {
        marginTop: 20,
    },
    integrationContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginBottom: 20,
        columnGap: 20,
        rowGap: 20
    },
    infoCard: {
        display: 'flex',
        flex: 1,
        maxWidth: 300
    },
    row: {
        flex: 1,
        marginBottom: 20,
        marginTop: 10,
    },
    centeredRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
        marginTop: 10,
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 10,
        textTransform: 'uppercase',
        fontSize: 13,
        color: Colors.gray3
    },
    button: {
        paddingTop: 50,
        width: '100%'
    }
})