import { combineReducers, AnyAction, Reducer } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authUserSlice'
import appStateReducer from './slices/appStateSlice'
import customerReducer from './slices/customerSlice'
import creditUsageReducer from './slices/creditUsageSlice'
import portalUserReducer from './slices/portalUserSlice'
import employeeReducer from './slices/employeeSlice'
import employeeActivationReducer from './slices/employeeActivationSlice'
import employeeDeactivationReducer from './slices/employeeDeactivationSlice'
import shiftReducer from './slices/shiftSlice'
import payDeviationReducer from './slices/payDeviationSlice'
import integrationLogReducer from './slices/integrationLogSlice'
import payrollReducer from './slices/payrollSlice'
import dashboardMetricsReducer from './slices/dashboardMetricsSlice'
import payScheduleReducer from './slices/payScheduleSlice'
import fileReducer from './slices/fileSlice'
import payoutBasisReducer from './slices/payoutBasisSlice'
import agiAdjustmentReducer from './slices/agiAdjustmentSlice'
import foraAdjustmentReducer from './slices/foraAdjustmentSlice'
import bookkeepingConfigurationReducer from './slices/bookkeepingConfigurationSlice'
import employerInitiatedPayoutReducer from './slices/employerInitiatedPayoutSlice'
import portalRolesReducer from './slices/portalRolesSlice'

// Integrations
import fortnoxIntegrationReducer from './slices/integrations/fortnoxIntegrationSlice'
import plandayIntegrationReducer from './slices/integrations/plandayIntegrationSlice'
import tengellaIntegrationReducer from './slices/integrations/tengellaIntegrationSlice'

const appReducer = combineReducers({
    appState: appStateReducer,
    auth: authReducer,
    customer: customerReducer,
    paySchedule: payScheduleReducer,
    employee: employeeReducer,
    employeeActivation: employeeActivationReducer,
    employeeDeactivation: employeeDeactivationReducer,
    shift: shiftReducer,
    creditUsage: creditUsageReducer,
    portalUser: portalUserReducer,
    payDeviations: payDeviationReducer,
    integrationLog: integrationLogReducer,
    payroll: payrollReducer,
    dashboardMetrics: dashboardMetricsReducer,
    files: fileReducer,
    payoutBasis: payoutBasisReducer,
    agiAdjustment: agiAdjustmentReducer,
    foraAdjustment: foraAdjustmentReducer,
    bookkeepingConfiguration: bookkeepingConfigurationReducer,
    employerInitiatedPayouts: employerInitiatedPayoutReducer,
    portalRoles: portalRolesReducer,

    // integrations
    fortnoxIntegration: fortnoxIntegrationReducer,
    plandayIntegration: plandayIntegrationReducer,
    tengellaIntegration: tengellaIntegrationReducer
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'authUser/logout') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
  };

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof appReducer>
export type AppDispatch = typeof store.dispatch
