import {apiConfig} from "../apiConfig"
import useFetchClient from "./useFetchClient"
import {CreateOrUpdatePortalRoleModel, PortalRoleModel} from "../redux/types";
import {useAppDispatch} from "../redux/hooks";
import {
  loading,
  portalRoleCreated,
  portalRoleCreateFailure,
  portalRoleDeleted,
  portalRoleDeleteFailure,
  portalRolesFetched,
  portalRolesFetchFailure,
  portalRoleUpdated,
  portalRoleUpdateFailure
} from "../redux/slices/portalRolesSlice";

const usePortalRolesActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getRoles = () => {
    dispatch(loading())

    return fetchClient.get(apiConfig.routes.roles)
      .then(response => {
        dispatch(portalRolesFetched(response.data as PortalRoleModel[]))
      })
      .catch(error => {
        console.log('getRoles error:', error)
        dispatch(portalRolesFetchFailure(error))
      })
  }

  const createRole = (role: CreateOrUpdatePortalRoleModel) => {
    dispatch(loading())

    return fetchClient.post(apiConfig.routes.roles, role)
      .then(response => {
        dispatch(portalRoleCreated(response.data as PortalRoleModel))
      })
      .catch(error => {
        console.log('createRole error:', error)
        dispatch(portalRoleCreateFailure(error))
      })
  }

  const updateRole = (roleId: string, role: CreateOrUpdatePortalRoleModel) => {
    dispatch(loading())
    const url = `${apiConfig.routes.roles}${roleId}`

    return fetchClient.post(url, role)
      .then(response => {
        dispatch(portalRoleUpdated(response.data as PortalRoleModel))
      })
      .catch(error => {
        console.log('updateRole error:', error)
        dispatch(portalRoleUpdateFailure(error))
      })
  }

  const deleteRole = (roleId: string) => {
    dispatch(loading())
    const url = `${apiConfig.routes.roles}${roleId}`

    return fetchClient.delete(url)
      .then(() => {
        dispatch(portalRoleDeleted(roleId))
      })
      .catch(error => {
        console.log('deleteRole error:', error)
        dispatch(portalRoleDeleteFailure(error))
      })
  }

  return {
    getRoles,
    createRole,
    updateRole,
    deleteRole
  }
}

export default usePortalRolesActions
