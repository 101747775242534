import {useEffect, useState} from "react"
import {createUseStyles} from 'react-jss'
import {useAppDispatch, useAppSelector} from "../redux/hooks"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import usePayrollActions from "../hooks/usePayrollActions"
import {errorHandled as payrollErrorHandled, pageIndexChanged} from '../redux/slices/payrollSlice'
import PayrollItemList from "../components/Payroll/PayrollItemList"
import {EmployerInitiatedPayoutJobListItem, UpcomingPayroll, UploadPayrollFileRowsModel} from "../redux/types"
import UpcomingPayrollCard from "../components/Payroll/UpcomingPayrollCard"
import {useTranslation} from "react-i18next"
import Button from "../components/Button"
import UploadFileModal from "../components/UploadFileModal"
import AddOffCyclePaymentModal, {OffCyclePaymentCreationMethod} from "../components/AddOffCyclePaymentModal"
import ErrorModal from "../components/ErrorModal"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"
import {Colors} from "../constants/colors"
import {faExclamation} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useNavigate, useSearchParams} from "react-router-dom"
import PayRunsModal from "../components/Payroll/PayRunsModal"
import CreatePayrollFileRowsModal from "../components/Payroll/CreatePayrollFileRowsModal";
import EmployerInitiatedPayoutCard from "../components/Payroll/EmployerInitiatedPayoutCard";
import usePayrollItems from "../components/Payroll/usePayrollItems";
import useEmployerInitiatedPayoutActions from "../hooks/useEmployerInitiatedPayoutActions";
import {errorHandled as employerInitiatedPayoutErrorHandled} from '../redux/slices/employerInitiatedPayoutSlice'

const Payrolls = () => {
  const styles = useStyles()
  const {t} = useTranslation('payrolls');
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    getPayrollData,
    getPayRunsForPayday,
    uploadPayrollFile,
    deletePayrollFile,
    uploadOffCyclePayrollFile,
    deleteOffCyclePayrollFile,
    uploadPayrollFileRows,
    uploadOffCyclePayrollFileRows
  } = usePayrollActions()

  const {getJobs, cancelJob} = useEmployerInitiatedPayoutActions()

  const [offCyclePaymentPayoutDate, setOffCyclePaymentPayoutDate] = useState<string>()
  const [offCyclePaymentDateSelectionModalVisible, setOffCyclePaymentDateSelectionModalVisible] = useState<boolean>(false)
  const [offCyclePaymentUploadFileModalVisible, setOffCyclePaymentUploadFileModalVisible] = useState<boolean>(false)
  const [offCyclePaymentManualRowsModalVisible, setOffCyclePaymentManualRowsModalVisible] = useState<boolean>(false)
  const [payrollErrorModalVisible, setPayrollErrorModalVisible] = useState<boolean>(false)
  const [payRunsModalVisible, setPayRunsModalVisible] = useState(false)
  const [employerInitiatedPayoutErrorModalVisible, setEmployerInitiatedPayoutErrorModalVisible] = useState(false)

  const payrollLoading = useAppSelector(state => state.payroll.loading)
  const payrolls = useAppSelector(state => state.payroll.payrolls)
  const payrollError = useAppSelector(state => state.payroll.error)
  const payRuns = useAppSelector(state => state.payroll.payRuns)
  const pageIndex = useAppSelector(state => state.payroll.pageIndex)
  const pageSize = useAppSelector(state => state.payroll.pageSize)

  const employerInitiatedPayoutsLoading = useAppSelector(state => state.employerInitiatedPayouts.loading)
  const employerInitiatedPayouts = useAppSelector(state => state.employerInitiatedPayouts.jobs)
  const employerInitiatedPayoutsError = useAppSelector(state => state.employerInitiatedPayouts.error)

  const employeesPendingDeactivation = useAppSelector(state => state.employeeDeactivation.pendingEmployees)
  const customer = useAppSelector(state => state.customer.customer)

  const {upcomingPayrollPageItems, historicPayrollPageItems} = usePayrollItems()

  useEffect(() => {
    const refresh = searchParams.get('refresh')
    if (payrolls.length === 0 || refresh === 'true') {
      getPayrollData()
    }

    if (employerInitiatedPayouts.length === 0 || refresh === 'true') {
      getJobs()
    }
  }, [])

  useDidUpdateEffect(() => {
    setPayRunsModalVisible(true)
  }, [payRuns])

  useDidUpdateEffect(() => {
    if (payrollError) {
      setPayrollErrorModalVisible(true)
    }
  }, [payrollError])

  useDidUpdateEffect(() => {
    if (employerInitiatedPayoutsError) {
      setEmployerInitiatedPayoutErrorModalVisible(true)
    }
  }, [employerInitiatedPayoutsError])

  const handlePayrollError = () => {
    dispatch(payrollErrorHandled())
    setPayrollErrorModalVisible(false)
  }

  const handleEmployerInitiatedPayoutError = () => {
    dispatch(employerInitiatedPayoutErrorHandled())
    setEmployerInitiatedPayoutErrorModalVisible(false)
  }

  const handleUploadedPayrollFile = (payroll: UpcomingPayroll, file: File) => {
    if (payroll.offCycle) {
      uploadOffCyclePayrollFile(new Date().getTime().toString(), payroll.paymentDate, file)
    } else {
      uploadPayrollFile(new Date().getTime().toString(), payroll.paymentDate, file)
    }
  }

  const handleUploadPayrollFileRows = (payroll: UpcomingPayroll, uploadPayrollFileRowsModel: UploadPayrollFileRowsModel) => {
    if (payroll.offCycle) {
      uploadOffCyclePayrollFileRows(new Date().getTime().toString(), payroll.paymentDate, uploadPayrollFileRowsModel)
    } else {
      uploadPayrollFileRows(new Date().getTime().toString(), payroll.paymentDate, uploadPayrollFileRowsModel)
    }
  }

  const handleOffCyclePaymentPayoutDateSelected = (date: string, creationMethod: OffCyclePaymentCreationMethod) => {
    setOffCyclePaymentPayoutDate(date)
    setOffCyclePaymentDateSelectionModalVisible(false)
    if (creationMethod === 'file') {
      setOffCyclePaymentUploadFileModalVisible(true)
    } else {
      setOffCyclePaymentManualRowsModalVisible(true)
    }
  }

  const handleCreateNewOffCyclePayment = (file: File) => {
    if (offCyclePaymentPayoutDate) {
      uploadOffCyclePayrollFile(new Date().getTime().toString(), offCyclePaymentPayoutDate, file)
    }

    setOffCyclePaymentUploadFileModalVisible(false)
  }

  const handleCreateNewOffCyclePaymentRows = (uploadPayrollFileRowsModel: UploadPayrollFileRowsModel) => {
    if (offCyclePaymentPayoutDate) {
      uploadOffCyclePayrollFileRows(new Date().getTime().toString(), offCyclePaymentPayoutDate, uploadPayrollFileRowsModel)
    }

    setOffCyclePaymentManualRowsModalVisible(false)
  }

  const handleDeletePayrollFile = (payroll: UpcomingPayroll, externalId: string) => {
    if (payroll.offCycle) {
      deleteOffCyclePayrollFile(externalId, payroll.paymentDate)
    } else {
      deletePayrollFile(externalId, payroll.paymentDate)
    }
  }

  const handleCancelEmployerInitiatedPayoutJob = (job: EmployerInitiatedPayoutJobListItem) => {
    cancelJob(job.id)
  }

  const handleDisplayPayrollFileContentClicked = (paydayId: string) => {
    getPayRunsForPayday(paydayId)
  }

  const renderUpcomingItems = () => {
    return upcomingPayrollPageItems.map((item, index) => {
      if (item.type === 'upcomingPayroll') {
        return <UpcomingPayrollCard
          key={`upcoming${index}`}
          upcomingPayroll={item.upcomingPayroll}
          isNext={index === 0}
          onUploadPayrollFile={handleUploadedPayrollFile}
          onDeletePayrollFile={handleDeletePayrollFile}
          onUploadPayrollFileRows={handleUploadPayrollFileRows}
        />
      } else if (item.type === 'employerInitiatedPayout') {
        return <EmployerInitiatedPayoutCard
          key={`upcoming${index}`}
          employerInitiatedPayoutJobListItem={item.employerInitiatedPayout}
          onCancel={handleCancelEmployerInitiatedPayoutJob}
        />
      }
      return null
    })
  }

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  return (
    <>
      <Card className={styles.card}>
        <h2>
          {t('title')}
        </h2>
      </Card>
      {employeesPendingDeactivation && employeesPendingDeactivation.length > 0 &&
          <Card className={styles.warningCard}>
              <div className={styles.warningContainer}>
                  <FontAwesomeIcon
                      icon={faExclamation}
                      size='1x'
                      color={Colors.rubel_700}
                  />
                  <h6>
                    {t('pendingDeactivationsExistWarning')}
                  </h6>
              </div>
          </Card>
      }
      <Card className={styles.card}>
        <div className={styles.buttonContainer}>
            {(customer?.employer && customer?.employer.employerInitiatedPayoutsEnabled) &&
            <Button
                onClick={() => navigate(`/payroll/createEmployerInitiatedPayout`)}
                title={t('addEmployerInitiatedPayout')}
                className={styles.button}
            />
            }
            {(customer?.employer && !customer?.employer.enablePayrollFileAdjustment) &&
            <Button
                onClick={() => setOffCyclePaymentDateSelectionModalVisible(true)}
                title={t('addOffCyclePayment')}
                className={styles.button}
            />
            }
        </div>
        <div className={styles.upcomingContainer}>
          {renderUpcomingItems()}
        </div>
      </Card>
      <Card className={styles.card}>
        <h4>
          {t('history')}
        </h4>
        {payrolls &&
            <PayrollItemList
                payrollItems={historicPayrollPageItems}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPayRunInfoClicked={handleDisplayPayrollFileContentClicked}
            />}
      </Card>
      <FullScreenLoadingIndicator visible={payrollLoading || employerInitiatedPayoutsLoading}/>
      <AddOffCyclePaymentModal
        allowManualCreation={customer?.employer?.manualPayrollFileRowCreationEnabled ?? false}
        visible={offCyclePaymentDateSelectionModalVisible}
        onOkClick={handleOffCyclePaymentPayoutDateSelected}
        onCancelClick={() => setOffCyclePaymentDateSelectionModalVisible(false)}
      />
      <UploadFileModal
        visible={offCyclePaymentUploadFileModalVisible}
        multiFileUpload={false}
        title={t('addOffCyclePayment', {ns: 'payrolls'})}
        infoText={t('addOffCyclePaymentDescription', {ns: 'payrolls'})}
        onCancelClick={() => setOffCyclePaymentUploadFileModalVisible(false)}
        onUpload={files => handleCreateNewOffCyclePayment(files[0])}
      />
      <CreatePayrollFileRowsModal
        visible={offCyclePaymentManualRowsModalVisible}
        onCloseClick={() => setOffCyclePaymentManualRowsModalVisible(false)}
        onCreateClick={handleCreateNewOffCyclePaymentRows}
      />
      <PayRunsModal
        visible={payRunsModalVisible}
        rows={payRuns}
        onCloseClick={() => {
          setPayRunsModalVisible(false)
        }}
      />
      <ErrorModal
        visible={payrollErrorModalVisible}
        errorText={payrollError?.toString()}
        onCloseClick={handlePayrollError}
      />
      <ErrorModal
        visible={employerInitiatedPayoutErrorModalVisible}
        errorText={employerInitiatedPayoutsError?.toString()}
        onCloseClick={handleEmployerInitiatedPayoutError}
      />
    </>
  )
}

export default Payrolls

const useStyles = createUseStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 30,
    gap: 10
  },
  upcomingContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 20,
    columnGap: 20,
    rowGap: 20
  },
  card: {
    marginBottom: 20
  },
  button: {
    alignSelf: 'flex-end'
  },
  warningCard: {
    marginBottom: 20,
    backgroundColor: Colors.yen_300
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 6
  }
})
