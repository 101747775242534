import React, {useEffect} from "react"
import {createUseStyles} from 'react-jss'
import {useTranslation} from "react-i18next"
import {Image, Spinner} from "react-bootstrap";
import {BankIdStatus, useBankIdAuth} from "../../authentication/useBankIdAuth";
import BankID_logo from "../../assets/BankID_logo.png";
import TextButton from "../TextButton";
import {useAppSelector} from "../../redux/hooks";


interface Props {
  onAuthenticated: (token: string) => void | Promise<void>
}

const QrBankIdLogin = (props: Props) => {
  const {t} = useTranslation('login');
  const styles = useStyles()

  const {status, qrCode, hintCode, authenticateQr} = useBankIdAuth()
  const error = useAppSelector(state => state.auth.error)

  useEffect(() => {
    authenticateAndHandleResult()
  }, []);

  const authenticateAndHandleResult = async () => {
    authenticateQr()
      .then((token) => {
        if (!!token) {
          return props.onAuthenticated(token)
        }
      })
  }

  const renderHint = (hintCodeKey: string | undefined) => {
    let translation = t(`hintcode-qr-${hintCodeKey}`)

    if (translation !== `hintcode-qr-${hintCodeKey}`) {
      return (<label className={styles.hintText}>{translation}</label>)
    }
  }

  const renderCollecting = () => {
    if (hintCode === 'userSign') {
      return renderHint(hintCode)
    }

    if (!!qrCode) {
      return (
        <>
          <Image className={styles.qrCode} src={`data:image/png;base64, ${qrCode}`} fluid/>
          <ol>
            <li>{t('bankIdQrInstruction1')}</li>
            <li>{t('bankIdQrInstruction2')}</li>
            <li>{t('bankIdQrInstruction3')}</li>
          </ol>
        </>
      )
    }

    return (
      <Spinner as='span' animation='border'/>
    )
  }

  const renderFailure = (errorMessage: string) => {
    let translation = t(errorMessage)

    if (translation === errorMessage) {
      translation = t('unknownError')
    }

    return (
      <>
        <label className={styles.hintText}>{translation}</label>
        <TextButton onClick={authenticateAndHandleResult}>{t('retry')}</TextButton>
      </>
    )
  }


  const renderContent = () => {
    switch (status) {
      case BankIdStatus.Collecting:
        return renderCollecting()
      case BankIdStatus.Failure:
        return renderFailure(`hintcode-${hintCode}`)
    }

    if (!!error) {
      return renderFailure('exchangeError')
    }

    return (
      <Spinner as='span' animation='border'/>
    )
  }

  return (
    <div className={styles.content}>
      <Image className={styles.logo} src={BankID_logo} fluid/>
      {renderContent()}
    </div>
  )
}

export default QrBankIdLogin

const useStyles = createUseStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10
  },
  qrCode: {
    width: 200,
    height: 200
  },
  logo: {
    width: 200
  },
  hintText: {
    textAlign: 'center'
  }
})
