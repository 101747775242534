import { CurrentUser, PortalRoleModel } from "../redux/types";

export const hasAnyRequiredPolicy = (user: CurrentUser | null, currentCustomerId: string | null, ...requiredPolicies: (keyof PortalRoleModel)[]) => {
    if (!user || !currentCustomerId) {
        return false
    }

    if (requiredPolicies.length === 0) {
        return true
    }

    const customerPortalUser = user.customers.find(x => x.customerId === currentCustomerId)
    if (!customerPortalUser) {
        return false
    }

    if (customerPortalUser.isOwner) {
        return true
    }

    return requiredPolicies.some(policy =>
      customerPortalUser.portalRoles.some(role => role[policy])
    );
}

export const userHasAuthorization = (user: CurrentUser, currentCustomerId: string, authorizationPredicate: (role: PortalRoleModel) => boolean) => {
    const customerPortalUser = user.customers.find(x => x.customerId === currentCustomerId)
    if (!customerPortalUser) {
        return false
    }

    if (customerPortalUser.isOwner) {
        return true
    }

    return customerPortalUser.portalRoles.some(authorizationPredicate)
}
