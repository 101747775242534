import { useCallback, useEffect, useState } from 'react';

const useHashParam = (key: string) => {
  const getHashParam = useCallback(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    return params.get(key);
  }, [key]);

  const [paramValue, setParamValue] = useState(getHashParam);

  const updateHashParam = useCallback((value: string | undefined) => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    if (value === undefined) {
      params.delete(key);
    } else {
      params.set(key, value);
    }
    window.location.hash = params.toString();
  }, [key]);

  useEffect(() => {
    const hashChangeHandler = () => {
      setParamValue(getHashParam());
    };

    window.addEventListener('hashchange', hashChangeHandler);

    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, [getHashParam]);

  return {
    hashParam: paramValue,
    setHashParam: updateHashParam,
  };
};

export default useHashParam;
