import {useEffect, useState} from "react"
import {createUseStyles} from 'react-jss'
import ConfirmModal from "../components/ConfirmModal"
import EditCustomerPortalUserModal from "../components/EditCustomerPortalUserModal"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import UserList from "../components/UserList/UserList"
import usePortalUserActions from "../hooks/usePortalUserActions"
import {CreateCustomerPortalUserModel, CustomerPortalUser, UpdateCustomerPortalUserModel} from "../redux/types"
import Button from '../components/Button'
import {useAppSelector} from "../redux/hooks"
import Card from "../components/Card"
import {useTranslation} from "react-i18next"
import usePortalRolesActions from "../hooks/usePortalRolesActions";

const PortalUsers = () => {
  const styles = useStyles()
  const {t} = useTranslation('portalUsers');

  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState<boolean>(false)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
  const [deleteCandidate, setDeleteCandidate] = useState<CustomerPortalUser>()
  const [editedUser, setEditedUser] = useState<CustomerPortalUser>()

  const {getUsers, createUser, updateUser, deleteUser} = usePortalUserActions()
  const usersLoading = useAppSelector(state => state.portalUser.loading)
  const portalUsers = useAppSelector(state => state.portalUser.portalUsers)
  const pageIndex = useAppSelector(state => state.portalUser.pageIndex)
  const pageSize = useAppSelector(state => state.portalUser.pageSize)

  const portalRoles = useAppSelector(state => state.portalRoles.portalRoles)
  const rolesLoading = useAppSelector(state => state.portalRoles.loading)
  const {getRoles} = usePortalRolesActions()

  useEffect(() => {
    if (portalUsers.length === 0) {
      getUsers()
    }
  }, [])

  useEffect(() => {
    if (portalRoles.length === 0) {
      getRoles()
    }
  }, []);

  const onAddClick = () => {
    setEditedUser(undefined)
    setEditModalVisible(true)
  }

  const handleUserAdded = (user: CreateCustomerPortalUserModel) => {
    createUser(user)
    setEditModalVisible(false)
  }

  const onEditClick = (user: CustomerPortalUser) => {
    setEditedUser(user)
    setEditModalVisible(true)
  }

  const handleUserEdited = (user: UpdateCustomerPortalUserModel) => {
    if (editedUser) {
      updateUser(editedUser.portalUserId, user)
      setEditedUser(undefined)
      setEditModalVisible(false)
    }
  }

  const handleUserEditCancelled = () => {
    setEditedUser(undefined)
    setEditModalVisible(false)
  }

  const onDeleteClick = (user: CustomerPortalUser) => {
    setDeleteCandidate(user)
    setConfirmDeleteModalVisible(true)
  }

  const handleDeleteConfirmed = () => {
    if (deleteCandidate) {
      deleteUser(deleteCandidate)
      setDeleteCandidate(undefined)
    }
    setConfirmDeleteModalVisible(false)
  }

  return (
    <>
      <Card className={styles.card}>
        <h2>
          {t('title')}
        </h2>
      </Card>
      <Card>
        <div className={styles.buttonContainer}>
          <Button onClick={onAddClick} title={t('addPortalUser')}/>
        </div>
        {portalUsers &&
            <UserList
                users={portalUsers}
                portalRoles={portalRoles}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
            />}
        <EditCustomerPortalUserModal
          key='editUserModal'
          initialValue={editedUser}
          portalRoles={portalRoles}
          visible={editModalVisible}
          onCancelClick={handleUserEditCancelled}
          onCreate={handleUserAdded}
          onUpdate={handleUserEdited}
        />
        <ConfirmModal
          visible={confirmDeleteModalVisible}
          confirmText={t('confirmDeleteTemplate', {user: `${deleteCandidate?.givenName ?? ''} ${deleteCandidate?.familyName ?? ''}`})}
          onConfirmClick={handleDeleteConfirmed}
          onCancelClick={() => setConfirmDeleteModalVisible(false)}
        />
        <FullScreenLoadingIndicator visible={usersLoading || rolesLoading}/>
      </Card>
    </>
  )
}

export default PortalUsers

const useStyles = createUseStyles({
  card: {
    marginBottom: 20
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 10
  }
})
