import {useEffect, useState} from "react"
import {createUseStyles} from 'react-jss'
import useCreditUsageActions from "../hooks/useCreditUsageActions"
import {Bar, Line} from 'react-chartjs-2'
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import {Colors} from "../constants/colors"
import {formatDateString} from "../util/date"
import {useAppSelector} from "../redux/hooks"
import Card from "../components/Card"
import ValueCard from "../components/ValueCard"
import {useTranslation} from "react-i18next"

const CreditUsage = () => {
  const styles = useStyles()
  const {t} = useTranslation('creditUsage');
  const {getCreditUsage} = useCreditUsageActions()

  const loading = useAppSelector(state => state.creditUsage.loading)
  const creditUsage = useAppSelector(state => state.creditUsage.creditUsage)

  const [totalUsageChartData, setTotalUsageChartData] = useState<any>()
  const [perPaydayUsageChartData, setPerPaydayUsageChartData] = useState<any>()
  const [currency, setCurrency] = useState<string>()

  const calculateUsedCreditPercentage = () => {
    if (creditUsage != null) {
      return (100 * creditUsage.totalCreditUsage.amount / creditUsage.creditLimit.amount).toFixed(0)
    }
    return 0
  }

  const totalUsageChartOptions = {
    interaction: {
      intersect: false
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw.toLocaleString('sv-SE') + ' ' + currency?.toLocaleUpperCase()
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: string | number) => {
            return value.toLocaleString('sv-SE') + ' ' + currency?.toLocaleUpperCase()
          }
        }
      }
    }
  }

  const perPaydayUsageChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw.toLocaleString('sv-SE') + ' ' + currency?.toLocaleUpperCase()
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value: string | number) => {
            return value.toLocaleString('sv-SE') + ' ' + currency?.toLocaleUpperCase()
          }
        }
      }
    }
  }

  useEffect(() => {
    if (creditUsage === null) {
      getCreditUsage(90)
    }
  }, [])

  useEffect(() => {
    if (creditUsage) {
      setCurrency(creditUsage.creditLimit.currencyCode)

      setTotalUsageChartData({
        labels: creditUsage.dailyCreditUsage.map((item) => formatDateString(item.date)),
        datasets: [
          {
            label: t('creditUsage'),
            data: creditUsage.dailyCreditUsage.map((item) => item.creditUsage.amount),
            fill: false,
            backgroundColor: Colors.euro_300,
            borderColor: Colors.euro_300,
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
            pointStyle: 'circle',
            pointRadius: 3,
          },
          {
            label: t('creditLimit'),
            data: creditUsage.dailyCreditUsage.map((item) => item.creditLimit?.amount),
            fill: false,
            backgroundColor: Colors.gray3,
            borderColor: Colors.gray3,
            cubicInterpolationMode: 'monotone',
            tension: 0.4,
            pointStyle: 'circle',
            pointRadius: 3,
          },
        ],
      })

      setPerPaydayUsageChartData({
        labels: creditUsage.dailyCreditUsage.map((item) => formatDateString(item.date)),
        datasets: [
          {
            label: t('upcomingPayday'),
            data: creditUsage.dailyCreditUsage.map((item) => item.creditUsageForUpcomingPayday?.amount ?? 0),
            backgroundColor: Colors.euro_300 + '88',
          },
          {
            label: t('followingPayday'),
            data: creditUsage.dailyCreditUsage.map((item) => item.creditUsageForFollowingPayday?.amount ?? 0),
            backgroundColor: Colors.gray2 + '88',
          },
        ],
      })
    }
  }, [creditUsage])

  const renderPaydaysInfoCards = () => {
    return creditUsage?.creditUsagePerPayday.map((item, index) => {
      return (
        <ValueCard
          key={`paydayInfoCard${index}`}
          title={t('creditUsagePayday', {payday: item.paymentDate})}
          value={item.creditUsage.amount.toLocaleString('sv-SE')}
          unit={item.creditUsage.currencyCode.toLocaleUpperCase()}
          color={Colors.gray4}
        />
      )
    })
  }

  return (

    <>
      <Card className={styles.card}>
        <h2>
          {t('title')}
        </h2>
      </Card>
      <div className={styles.infoCardContainer}>
        <ValueCard
          title={t('creditUsageTotal')}
          value={creditUsage?.totalCreditUsage.amount.toLocaleString('sv-SE')}
          unit={creditUsage?.totalCreditUsage.currencyCode.toLocaleUpperCase()}
          footer={t('creditUsageTotalFooter', {percentage: calculateUsedCreditPercentage()})}
          color={Colors.gray4}
        />
        {renderPaydaysInfoCards()}
        <ValueCard
          title={t('creditLimit')}
          value={creditUsage?.creditLimit.amount.toLocaleString('sv-SE')}
          unit={creditUsage?.creditLimit.currencyCode.toLocaleUpperCase()}
          color={Colors.gray4}
        />
      </div>
      <Card className={styles.card}>
        <h3>{t('totalCreditUsageGraphTitle')}</h3>
        <div style={{position: 'relative'}}>
          {totalUsageChartData &&
              <Line data={totalUsageChartData} options={totalUsageChartOptions}/>}
        </div>
      </Card>
      <Card>
        <h3>{t('creditUsagePerPaydayGraphTitle')}</h3>
        <div style={{position: 'relative'}}>
          {perPaydayUsageChartData &&
              <Bar data={perPaydayUsageChartData} options={perPaydayUsageChartOptions}/>}
        </div>
      </Card>
      <FullScreenLoadingIndicator visible={loading}/>
    </>
  )
}

export default CreditUsage

const useStyles = createUseStyles({
  card: {
    marginBottom: 20
  },
  infoCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    columnGap: 20
  },
})
