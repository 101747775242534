import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {CreateOrUpdatePortalRoleModel, PortalRoleModel} from "../../redux/types"
import {useTranslation} from 'react-i18next'
import Modal from "../Modal";
import Switch from "../Switch";
import InputField from "../InputField";

interface Props {
  portalRole: PortalRoleModel | undefined
  visible: boolean
  onConfirmClick: (createOrUpdatePortalRoleModel: CreateOrUpdatePortalRoleModel) => void
  onCancelClick: () => void
}

const emptyCreateOrUpdatePortalRoleModel: CreateOrUpdatePortalRoleModel = {
  name: '',
  allowManageUsers: false,
  allowManagePaySchedules: false,
  allowManagePayroll: false,
  allowManageEmployees: false,
  allowManageShifts: false,
  allowManagePayDeviations: false,
  allowManageEmployeeActivations: false,
  allowManageCompanySettings: false,
  allowReadCreditReport: false,
  allowManageBookkeepingSettings: false,
  allowManageFora: false,
  allowManageAgi: false,
  allowViewIntegrationLog: false,
  allowManageIntegrations: false,
  receiveAgiAdjustmentReminder: false,
  receiveCreditReport: false,
  receiveActivationNotification: false,
  receivePayrollSystemImportFileNotification: false,
  receiveDeductionRepaymentNotification: false,
  receivePayrollFileReminder: false,
  receivePaydayGenerationNotification: false,
  receivePayrollFileValidationErrorNotification: false
}

const EditPortalRoleModal = ({portalRole, visible, onConfirmClick, onCancelClick}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'portalRoles']);
  const [editPortalRoleModel, setEditPortalRoleModel] = useState<CreateOrUpdatePortalRoleModel>(emptyCreateOrUpdatePortalRoleModel)
  const [nameValid, setNameValid] = useState(true)

  const isEdit = !!portalRole

  useEffect(() => {
    if (portalRole) {
      setEditPortalRoleModel({...portalRole} as CreateOrUpdatePortalRoleModel)
    } else {
      setEditPortalRoleModel(emptyCreateOrUpdatePortalRoleModel)
    }
  }, [portalRole, visible]);


  const onNameChanged = (value: string) => {
    setEditPortalRoleModel({...editPortalRoleModel, name: value})
    setNameValid(value.length >= 3)
  }

  const handleOnOkClick = () => {
    if (!nameValid) {
      return
    }
    onConfirmClick(editPortalRoleModel);
  }

  const renderPropertySwitch = (propertyName: keyof CreateOrUpdatePortalRoleModel) => {
    const enabled = !!editPortalRoleModel[propertyName]
    const name = propertyName as string;

    return (
      <div className={styles.propertyContainer}>
        <Switch
          id={`switch-${name}`}
          checked={enabled}
          label={t(name, {ns: 'portalRoles'})}
          onChange={() => {
            setEditPortalRoleModel({...editPortalRoleModel, [propertyName]: !enabled})
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      title={isEdit ? t('editPortalRole', {ns: 'portalRoles'}) : t('addPortalRole', {ns: 'portalRoles'})}
      okButtonTitle={t('save', {ns: 'common'})}
      cancelButtonTitle={t('cancel', {ns: 'common'})}
      visible={visible}
      onHide={onCancelClick}
      onOkClick={handleOnOkClick}
      okButtonDisabled={!editPortalRoleModel.name || !nameValid}
      onCancelClick={onCancelClick}
      size={'lg'}
    >
      <div className={styles.container} style={{paddingBottom: 20}}>
        <InputField
          valid={nameValid}
          mandatory={true}
          id='name'
          value={editPortalRoleModel.name}
          onChange={onNameChanged}
          label={t('name', {ns: 'common'})}
          placeholder=''
        />
      </div>

      <div className={styles.container}>
        <div className={styles.column}>
          <h4>{t('policies', {ns: 'portalRoles'})}</h4>

          <div className={styles.row}>
            {renderPropertySwitch("allowManageUsers")}
            {renderPropertySwitch("allowManagePaySchedules")}
            {renderPropertySwitch("allowManagePayroll")}
            {renderPropertySwitch("allowManageEmployees")}
            {renderPropertySwitch("allowManageShifts")}
            {renderPropertySwitch("allowManagePayDeviations")}
            {renderPropertySwitch("allowManageEmployeeActivations")}
            {renderPropertySwitch("allowManageCompanySettings")}
            {renderPropertySwitch("allowReadCreditReport")}
            {renderPropertySwitch("allowManageBookkeepingSettings")}
            {renderPropertySwitch("allowManageFora")}
            {renderPropertySwitch("allowManageAgi")}
            {renderPropertySwitch("allowViewIntegrationLog")}
            {renderPropertySwitch("allowManageIntegrations")}
          </div>
        </div>

        <div className={styles.column}>
          <h4>{t('reportsAndNotifications', {ns: 'portalRoles'})}</h4>

          <div className={styles.row}>
            {renderPropertySwitch("receiveAgiAdjustmentReminder")}
            {renderPropertySwitch("receiveCreditReport")}
            {renderPropertySwitch("receiveActivationNotification")}
            {renderPropertySwitch("receivePayrollSystemImportFileNotification")}
            {renderPropertySwitch("receiveDeductionRepaymentNotification")}
            {renderPropertySwitch("receivePayrollFileReminder")}
            {renderPropertySwitch("receivePaydayGenerationNotification")}
            {renderPropertySwitch("receivePayrollFileValidationErrorNotification")}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditPortalRoleModal

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    flex: 1,
    flexWrap: 'wrap'
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 4
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 10,
    minWidth: 200
  },
  propertyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    alignItems: 'center'
  }
})
