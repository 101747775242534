import { createUseStyles } from "react-jss"
import { BookkeepingAccountTypeRule } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import { useTranslation } from "react-i18next"
import BookkeepingAccountTypeRuleListItem from "./BookkeepingAccountTypeRuleListItem"
import { useEffect, useState } from "react"
import Button from "../Button"

interface Props {
  rules: BookkeepingAccountTypeRule[]
  onChange: (rules: BookkeepingAccountTypeRule[]) => void
}
  
const BookkeepingAccountTypeRuleList = ({rules, onChange}: Props) => {
  const styles = useStyles()
  const { t } = useTranslation(['common', 'bookkeepingSettings']);

  const [editIndex, setEditIndex] = useState<number|null>(null)
  const [internalRules, setInternalRules] = useState<BookkeepingAccountTypeRule[]>([])

  useEffect(() => {
    setInternalRules([...rules].sort(x => x.orderNumber)) 
  }, [rules])

  const addRule = () => {
    let newRules = [...internalRules]

    newRules.unshift({
      orderNumber: 0,
      account: 0,
      costCenter: null,
      project: null,
      filterEmployeeType: null,
      filterDepartment: null,
      filterCategory: null
    })

    updateRuleOrderNumbers(newRules)
    setInternalRules(newRules)
    setEditIndex(0)
  }

  const onRuleChanged = (rule: BookkeepingAccountTypeRule) => {
    if (editIndex != null) {
      const newRules = [...internalRules.slice(0, editIndex), rule, ...internalRules.slice(editIndex + 1)]
      setInternalRules(newRules)
      setEditIndex(null)
      onChange(newRules)
    }
  }

  const onRuleDeleted = (index: number) => {
    let newRules = [...internalRules]
    newRules.splice(index, 1)
    setInternalRules(newRules)
    setEditIndex(null)
    onChange(newRules)
  }

  const onMoveRuleUp = (index: number) => {
    if (index == 0)
      return
  
    const newRules = [...internalRules]
    const ruleToMove = newRules[index] 
    newRules[index] = newRules[index - 1]
    newRules[index - 1] = ruleToMove

    updateRuleOrderNumbers(newRules)
    setInternalRules(newRules)
    onChange(newRules)
  }

  const onMoveRuleDown = (index: number) => {
    if (index >= internalRules.length - 1)
      return

    const newRules = [...internalRules]
    const ruleToMove = newRules[index] 
    newRules[index] = newRules[index + 1]
    newRules[index + 1] = ruleToMove

    updateRuleOrderNumbers(newRules)
    setInternalRules(newRules)
    onChange(newRules)
  }

  const updateRuleOrderNumbers = (rules: BookkeepingAccountTypeRule[]) => {
    for (var i = 0; i < rules.length; i++) {
        rules[i] = {...rules[i], orderNumber: i}
    }
  }

  const renderHeader = () => {
    return (
      <div>
        <ListItemCard className={styles.listItemContainer}>
            <div className={styles.indexCell}>
                <strong>#</strong>
            </div>
            <div className={styles.smallCell}>
                <strong>{t('account', {ns: 'bookkeepingSettings'})}</strong>
            </div>
            <div className={styles.smallCell}>
                <strong>{t('costCenter', {ns: 'common'})}</strong>
            </div>
            <div className={styles.smallCell}>
                <strong>{t('projectDimension', {ns: 'bookkeepingSettings'})}</strong>
            </div>
            <div className={styles.cell}>
                <strong>{t('employeeType', {ns: 'bookkeepingSettings'})}</strong>
            </div>
            <div className={styles.cell}>
                <strong>{t('category', {ns: 'common'})}</strong>
            </div>
            <div className={styles.cell}>
                <strong>{t('department', {ns: 'common'})}</strong>
            </div>
            <div className={styles.cellButtonContainer}>

            </div>
        </ListItemCard>
      </div> 
    )
  }

  const renderRules = () => {
    return internalRules?.map((rule, index) => {
      return (
        <BookkeepingAccountTypeRuleListItem 
          key={`rule${index}`} 
          rule={rule}
          index={index}
          ruleCount={internalRules.length}
          editMode={index == editIndex}
          onEdit={setEditIndex}
          onEditCancelled={() => setEditIndex(null)}
          onSave={onRuleChanged}
          onDelete={onRuleDeleted}
          onMoveUp={onMoveRuleUp}
          onMoveDown={onMoveRuleDown}
        />
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderRules()}
      <div className={styles.addButtonContainer}>
        <Button 
          disabled={editIndex != null} 
          title={t('addRule', {ns: 'bookkeepingSettings'})} 
          onClick={addRule}
        />
      </div>
    </div>
  )
}

export default BookkeepingAccountTypeRuleList

const useStyles = createUseStyles({
  listItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  cell: {
    width: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center'
  },
  indexCell: {
    width: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center'
  },
  smallCell: {
    width: 120,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center'
  },
  cellButtonContainer: {
    display: 'flex',
    width: 80,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  addButtonContainer: {
    marginTop: 20
  },
})
