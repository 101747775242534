import {faCheck, faExclamation, faExclamationTriangle, faFile, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import {createUseStyles} from 'react-jss'
import {Colors} from "../../constants/colors"
import {Fonts} from '../../constants/fonts'
import {
  FileType,
  PayoutBasisFileType,
  PayrollFile,
  PayrollFileRow,
  UpcomingPayroll,
  UploadPayrollFileRowsModel
} from "../../redux/types"
import {formatDateString, formatDateTimeString} from '../../util/date'
import Card from '../Card'
import Button from '../Button'
import PayrollFileRowsModal from './PayrollFileRowsModal'
import PayrollFileErrorsModal from './PayrollFileErrorsModal'
import {useTranslation} from 'react-i18next'
import ConfirmModal from '../ConfirmModal'
import UploadFileModal from '../UploadFileModal'
import {useAppSelector} from '../../redux/hooks'
import File from '../File'
import {useNavigate} from "react-router-dom";
import CreatePayrollFileRowsModal from "./CreatePayrollFileRowsModal";

interface Props {
  upcomingPayroll: UpcomingPayroll
  isNext: boolean
  onUploadPayrollFile: (payroll: UpcomingPayroll, file: File) => void
  onDeletePayrollFile: (payroll: UpcomingPayroll, externalId: string) => void
  onUploadPayrollFileRows: (payroll: UpcomingPayroll, model: UploadPayrollFileRowsModel) => void
}

const UpcomingPayrollCard = ({
                               upcomingPayroll,
                               isNext,
                               onUploadPayrollFile,
                               onDeletePayrollFile,
                               onUploadPayrollFileRows
                             }: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'payrolls']);
  const navigate = useNavigate();

  const [fileUploadModalVisible, setFileUploadModalVisible] = useState<boolean>(false)
  const [fileRowsModalVisible, setFileRowsModalVisible] = useState<boolean>(false)
  const [validationErrorsModalVisible, setValidationErrorsModalVisible] = useState<boolean>(false)
  const [confirmDeletePayrollFileModalVisible, setConfirmDeletePayrollFileModalVisible] = useState<boolean>(false)
  const [createPayrollFileRowsModalVisible, setCreatePayrollFileRowsModalVisible] = useState<boolean>(false)

  const [selectedFileRows, setSelectedFileRows] = useState<PayrollFileRow[]>()
  const [payrollFileDeleteCandidate, setPayrollFileDeleteCandidate] = useState<PayrollFile>()

  const customer = useAppSelector(state => state.customer.customer)
  const files = useAppSelector(state => state.files.files)

  const handleDeletePayrollFileConfirmed = () => {
    if (payrollFileDeleteCandidate) {
      onDeletePayrollFile(upcomingPayroll, payrollFileDeleteCandidate.externalId)
      setPayrollFileDeleteCandidate(undefined)
    }

    setConfirmDeletePayrollFileModalVisible(false)
  }

  const handleCreatePayrollFileRows = (uploadPayrollFileRowsModel: UploadPayrollFileRowsModel) => {
    onUploadPayrollFileRows(upcomingPayroll, uploadPayrollFileRowsModel)
    setCreatePayrollFileRowsModalVisible(false)
  }

  const getCardBackgroundColor = () => {
    if (upcomingPayroll.payrollFileOverdue || upcomingPayroll.validationErrors?.length > 0) {
      return Colors.yuan_100
    }

    if (upcomingPayroll.payrollFileDue) {
      return Colors.yen_300
    }

    if (upcomingPayroll.payrollFileSubmitted && (!upcomingPayroll.validationErrors || upcomingPayroll.validationErrors.length === 0)) {
      return Colors.euro_50
    }

    return Colors.grayBackgroundApp
  }

  const renderStatusIcon = () => {
    let icon = undefined
    let color = undefined

    if (upcomingPayroll.payrollFileOverdue || upcomingPayroll.validationErrors?.length > 0) {
      icon = faExclamationTriangle
      color = Colors.rubel_700
    } else if (upcomingPayroll.payrollFileDue) {
      icon = faExclamation
      color = Colors.rubel_700
    } else if (upcomingPayroll.payrollFileSubmitted && (!upcomingPayroll.validationErrors || upcomingPayroll.validationErrors.length === 0)) {
      icon = faCheck
      color = Colors.dollar_900
    }

    return (
      <>
        {icon &&
            <FontAwesomeIcon
                icon={icon}
                size='3x'
                color={color}
            />
        }
      </>)
  }

  const renderStatusMessage = (message: string, subMessage?: string, latestSubmitDateIntro?: string, latestSubmitDate?: string) => {
    return (
      <div className={styles.statusMessageContainer}>
        <h5>{message}</h5>
        {subMessage && <h6>{subMessage}</h6>}
        {latestSubmitDateIntro && <h6>{latestSubmitDateIntro} <strong>{latestSubmitDate}</strong>.</h6>}
      </div>
    )
  }

  const renderViewValidationErrorsButton = (linkText: string) => {
    return (
      <Button title={linkText} onClick={() => setValidationErrorsModalVisible(true)}/>
    )
  }

  const renderUploadedFilesContainer = (errorsExist: boolean, files: PayrollFile[]) => {
    return (
      <div className={styles.uploadedFilesContainer}>
        {files.map((file, index) => {
          return (
            <div className={styles.uploadedFileContainer} key={`container_${index}`}>
              <FontAwesomeIcon
                style={{cursor: 'pointer'}}
                icon={faFile}
                size='3x'
                color={errorsExist ? Colors.rubel_700 : Colors.dollar_900}
                title={t('showContent', {ns: 'common'})}
                onClick={() => {
                  setSelectedFileRows(file.payrollFileRows)
                  setFileRowsModalVisible(true)
                }}
              />
              <div>
                <span style={{fontSize: 11}}>{t('submitted', {ns: 'payrolls'})}</span>
              </div>
              <span style={{fontFamily: Fonts.semiBold, fontSize: 11}}>
                            {formatDateTimeString(file.submitDate)}
                        </span>
              <FontAwesomeIcon
                style={{cursor: 'pointer'}}
                icon={faTrashAlt}
                color={Colors.gray1}
                title={t('delete', {ns: 'common'})}
                onClick={() => {
                  setPayrollFileDeleteCandidate(file)
                  setConfirmDeletePayrollFileModalVisible(true)
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const renderAddButtons = (linkText: string, introText?: string, discreteButton?: boolean) => {
    const onClickAction = () => {
      if (customer?.employer && customer.employer.enablePayrollFileAdjustment) {
        navigate(`/payroll/create?payday=${upcomingPayroll.paymentDate}`)
      } else {
        setFileUploadModalVisible(true)
      }
    }

    const onManualCreatePayrollFileRowsButtonClick = () => {
      setCreatePayrollFileRowsModalVisible(true)
    }

    return (
      <div className={styles.fileUploadButtonContainer}>
        {introText && <h6>{introText}</h6>}
        <Button title={linkText} variant={discreteButton ? 'negative' : 'positive'} onClick={onClickAction}/>
        {customer?.employer?.manualPayrollFileRowCreationEnabled &&
            <Button title={t('createPayrollFileRowsManually', {ns: 'payrolls'})} variant={'negative'}
                    onClick={onManualCreatePayrollFileRowsButtonClick}/>
        }
      </div>
    )
  }

  const renderHeader = (labelText: string) => {
    return (
      <div className={styles.headerContainer}>
                <span className={styles.headerLabel}>
                    {labelText}
                </span>
        <span style={{fontFamily: Fonts.semiBold, fontSize: 20}}>
                    {formatDateString(upcomingPayroll.paymentDate)}
                </span>
      </div>
    )
  }

  const renderContentByStatus = () => {
    if (upcomingPayroll.validationErrors?.length > 0) {
      return renderValidationErrorContent()
    } else if (upcomingPayroll.payrollFileOverdue) {
      return renderOverdueContent()
    } else if (upcomingPayroll.payrollFileDue) {
      return renderDueContent()
    } else if (upcomingPayroll.payrollFileSubmitted && (!upcomingPayroll.validationErrors || upcomingPayroll.validationErrors.length === 0)) {
      return renderSuccessContent()
    } else if (isNext) {
      return renderUpcomingContent()
    } else {
      return renderFutureContent()
    }
  }

  const renderFutureContent = () => {
    return (
      <>
        {renderHeader(t('futurePayday', {ns: 'payrolls'}))}
        {renderStatusMessage(t('plentyOfTimeLeftTitle', {ns: 'payrolls'}), t('plentyOfTimeLeftSubtitle', {ns: 'payrolls'}))}
        <div style={{height: '100%'}}></div>
      </>
    )
  }

  const renderUpcomingContent = () => {
    return (
      <>
        {renderHeader(t('upcomingPayday', {ns: 'payrolls'}))}
        {renderStatusMessage(t('someTimeLeftTitle', {ns: 'payrolls'}), t('someTimeLeftSubtitle', {ns: 'payrolls'}), t('latestSubmitDateIntro', {ns: 'payrolls'}), formatDateString(upcomingPayroll.latestPayrollFileSubmitDate))}
        {renderImportFilesForPayday()}
        {renderAddButtons(t('uploadPayrollFile', {ns: 'payrolls'}))}
      </>
    )
  }

  const renderDueContent = () => {
    return (
      <>
        {renderHeader(t('upcomingPayday', {ns: 'payrolls'}))}
        {renderStatusMessage(t('gettingCloseTitle', {ns: 'payrolls'}), t('gettingCloseSubtitle', {ns: 'payrolls'}), t('latestSubmitDateIntro', {ns: 'payrolls'}), formatDateString(upcomingPayroll.latestPayrollFileSubmitDate))}
        {renderImportFilesForPayday()}
        {renderAddButtons(t('uploadPayrollFile', {ns: 'payrolls'}))}
      </>
    )
  }

  const renderOverdueContent = () => {
    return (
      <>
        {renderHeader(t('overduePayday', {ns: 'payrolls'}))}
        {renderStatusMessage(t('overdueTitle', {ns: 'payrolls'}), t('overdueSubtitle', {ns: 'payrolls'}), t('uploadFileNowIntro', {ns: 'payrolls'}), t('now', {ns: 'payrolls'}))}
        {renderImportFilesForPayday()}
        {renderAddButtons(t('uploadPayrollFile', {ns: 'payrolls'}))}
      </>
    )
  }

  const renderValidationErrorContent = () => {
    return (
      <>
        {renderHeader(upcomingPayroll.offCycle ? t('offCyclePayment', {ns: 'payrolls'}) : t('upcomingPayday', {ns: 'payrolls'}))}
        {renderUploadedFilesContainer(true, upcomingPayroll.payrollFiles)}
        {renderStatusMessage(t('issuesFoundTitle', {ns: 'payrolls'}))}
        <div className={styles.modalButtonContainer}>
          {renderViewValidationErrorsButton(t('reviewIssuesTemplate', {
            ns: 'payrolls',
            issueCount: upcomingPayroll.validationErrors.length
          }))}
        </div>
        {renderAddButtons(t('uploadAnotherPayrollFile', {ns: 'payrolls'}), t('uploadAnotherPayrollFileIntro', {ns: 'payrolls'}), true)}
      </>
    )
  }

  const renderSuccessContent = () => {
    return (
      <>
        {renderHeader(upcomingPayroll.offCycle ? t('offCyclePayment', {ns: 'payrolls'}) : t('upcomingPayday', {ns: 'payrolls'}))}
        {renderUploadedFilesContainer(false, upcomingPayroll.payrollFiles)}
        {renderStatusMessage(t('successTitle', {ns: 'payrolls'}))}
        {renderAddButtons(t('uploadAnotherPayrollFile', {ns: 'payrolls'}), t('uploadAnotherPayrollFileIntro', {ns: 'payrolls'}), true)}
      </>
    )
  }

  const renderImportFilesForPayday = () => {
    const filesForPayday = files.filter(x =>
      x.type === FileType.payrollSystemBankAccountImportFile
      || (x.type === FileType.payrollSystemPayoutBasisImportFile &&
        x.payrollSystemPayoutBasisImportFile.fileType === PayoutBasisFileType.deductionBasisForPayday &&
        x.payrollSystemPayoutBasisImportFile.paydayId === upcomingPayroll.paydayId))

    if (filesForPayday.length > 0) {
      return (
        <Card className={styles.importFilesContainer}>
          <h6 style={{marginBottom: 10}}>{t('importFiles', {ns: 'payrolls'})}</h6>
          {filesForPayday.map((file, index) => {
            return (
              <File file={file} key={`file_${index}`}/>
            )
          })}
        </Card>
      )
    }
  }

  return (
    <Card className={styles.container} style={{backgroundColor: getCardBackgroundColor()}}>
      <div className={styles.statusIconContainer}>
        {renderStatusIcon()}
      </div>
      {renderContentByStatus()}
      <UploadFileModal
        visible={fileUploadModalVisible}
        multiFileUpload={false}
        title={t('uploadPayrollFile', {ns: 'payrolls'})}
        onCancelClick={() => setFileUploadModalVisible(false)}
        onUpload={(files) => {
          setFileUploadModalVisible(false)
          onUploadPayrollFile(upcomingPayroll, files[0])
        }}
      />
      <PayrollFileRowsModal
        visible={fileRowsModalVisible}
        rows={selectedFileRows != null ? selectedFileRows : upcomingPayroll?.payrollFiles.flatMap(x => x.payrollFileRows) ?? []}
        onCloseClick={() => {
          setFileRowsModalVisible(false)
          setSelectedFileRows(undefined)
        }}
      />
      <PayrollFileErrorsModal
        visible={validationErrorsModalVisible}
        errors={upcomingPayroll.validationErrors ?? []}
        onCloseClick={() => setValidationErrorsModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmDeletePayrollFileModalVisible}
        confirmText={t('confirmDeletePayrollFile', {ns: 'payrolls'})}
        onConfirmClick={handleDeletePayrollFileConfirmed}
        onCancelClick={() => setConfirmDeletePayrollFileModalVisible(false)}
      />
      <CreatePayrollFileRowsModal
        visible={createPayrollFileRowsModalVisible}
        onCreateClick={(model: UploadPayrollFileRowsModel) => handleCreatePayrollFileRows(model)}
        onCloseClick={() => setCreatePayrollFileRowsModalVisible(false)}
      />
    </Card>
  )
}

export default UpcomingPayrollCard

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minWidth: 400
  },
  statusIconContainer: {
    position: 'absolute',
    right: 20,
    top: 15
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column'
  },
  headerLabel: {
    color: Colors.euro_800,
    fontSize: 13,
    textTransform: 'uppercase'
  },
  statusMessageContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 10,
    rowGap: 15
  },
  uploadedFilesContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20
  },
  uploadedFileContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileUploadButtonContainer: {
    marginTop: 40,
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    rowGap: 6
  },
  modalButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10
  },
  validationErrorLinkContainer: {
    marginTop: 10,
    cursor: 'pointer'
  },
  parsedContentLinkContainer: {
    marginTop: 10,
    cursor: 'pointer'
  },
  importFilesContainer: {
    backgroundColor: Colors.grayBackgroundStrong,
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  }
})
