import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { useState } from "react";
import useAuthUserActions from "./auth/useAuthUserActions";

const usePasswordActions = () => {
    const fetchClient = useFetchClient()
    const { login } = useAuthUserActions()

    const [loading, setLoading] = useState<boolean>(false)
    const [completed, setCompleted] = useState<boolean>(false)
    const [error, setError] = useState<Error | string>()

    const sendSetPasswordLink = (email: string) => {
        setCompleted(false)
        setError(undefined)
        setLoading(true)

        return fetchClient.post(apiConfig.routes.sendPasswordLink, {email: email})
            .then(response => {
                setCompleted(true)
            })
            .catch(error => {
                console.log('sendSetPasswordLink error:', error)
                setError(error)
            }).finally(() => {
                setLoading(false)
            })
    }

    const setNewPasswordAndLogin = (email: string, password: string, token: string) => {
        setCompleted(false)
        setError(undefined)
        setLoading(true)

        return fetchClient.post(apiConfig.routes.password, {email: email, password: password, token: token})
            .then(response => {
                login(email, password)
            })
            .catch(error => {
                console.log('Setting of password failed:', error)
                setError(error)
            }).finally(() => {
                setLoading(false)
            })
    }

    return {
        sendSetPasswordLink,
        setNewPasswordAndLogin,
        loading,
        completed,
        error
    }
}

export default usePasswordActions
